import { Quotation } from '../../../../../../../@types/interface';
import { ProductTable } from '../../../../../../../shared/ProductTable';
import { ViewRowAnswerQuotation } from '../ViewRowAnswerQuotation';

interface Props {
  quotationReplies: Quotation | null;
  idItem: string;
}
export const ViewHeaderAnswerQuotation = ({
  quotationReplies,
  idItem,
}: Props) => {
  return (
    <div>
      {quotationReplies &&
        quotationReplies.answers_quotation &&
        quotationReplies.answers_quotation.map(quoteReplies => (
          <ProductTable.Row
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <ViewRowAnswerQuotation
              answersQuote={quoteReplies}
              idItem={idItem}
            />
          </ProductTable.Row>
        ))}
    </div>
  );
};
