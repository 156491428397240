import { useState } from 'react';
import {
  resendQuotationController,
  sendQuotationController,
} from '../../../controller';

export interface WorkshopOption {
  name: string;
  value: string;
}

export interface WorkshopList {
  option: WorkshopOption;
  selected: boolean;
}

interface SendQuotationProps {
  workshopList: WorkshopList[];
  quotationId: string;
}

export const useSendQuotation = () => {
  const [isSending, setIsSending] = useState(false);

  const sendQuotation = async ({
    quotationId,
    workshopList,
  }: SendQuotationProps) => {
    setIsSending(true);
    const selectedCompanies = workshopList.filter(
      workshop => workshop.selected,
    );
    const res = await sendQuotationController({
      quotationId,
      sendTo: selectedCompanies.map(company => company.option.value),
    });
    setIsSending(false);
    return res;
  };

  const reSendQuotation = async ({
    quotationId,
    workshopList,
  }: SendQuotationProps) => {
    setIsSending(true);
    const selectedCompanies = workshopList.filter(
      workshop => workshop.selected,
    );
    const res = await resendQuotationController({
      quotationId,
      sendTo: selectedCompanies.map(company => company.option.value),
    });
    setIsSending(false);
    return res;
  };

  return {
    sendQuotation,
    isSending,
    reSendQuotation,
  };
};
