import { Controller } from 'react-hook-form';
import { Modal } from '../../../../../../../shared/Modal';
import { Textarea } from '../../../../../../../shared/Textarea';
import { Input } from '../../../../../../../shared/Input';
import styles from './approve-quotation-modal.module.css';
import { AnswerQuotation } from '../../../../../../../@types/interface';
import { Button } from '../../../../../../../shared/Button';
import { ConfirmModal } from './components/ConfirmModal';
import { useApproveQuotation } from './useApproveQuotation';
import { useResponsive } from '../../../../../../../hooks/useResponsive';
import { ViewItemQuotationList } from '../../ViewItemQuotationList';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  quotationAnswer: AnswerQuotation | null;
}

export const ApproveQuotationModal = ({
  isOpen,
  onClose,
  quotationAnswer,
}: Props) => {
  const {
    control,
    handleConfirmQuotation,
    handleConfirmModal,
    isConfirmModalOpen,
    isConfirmingQuotation,
  } = useApproveQuotation({
    onClose,
    quotationAnswer,
    isOpen,
  });
  const { sizeMobile } = useResponsive();

  return (
    <>
      <ConfirmModal
        isLoading={isConfirmingQuotation}
        isOpen={isConfirmModalOpen}
        onClose={() => handleConfirmModal('close')}
        onConfirm={handleConfirmQuotation}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={`Visualizar Cotação - N° ${
          quotationAnswer?.quotation?.QUOTATION || ''
        }`}
        size={!sizeMobile ? 'large' : 'full'}
        footer={
          <div className={styles['modal-actions']}>
            <Button
              variant="register"
              handleClick={() => handleConfirmModal('open')}
              style={{
                height: '2rem',
                fontSize: '1.5rem',
                width: '9.5rem',
              }}
            >
              Confirmar Pedido
            </Button>
          </div>
        }
      >
        <Input
          name="origin"
          label="Origem:"
          value={quotationAnswer?.quotation?.workshop?.fantasy_name ?? ''}
          disabled
          style={{ fontWeight: '600', color: '#000000' }}
        />
        <div className={styles['vehicle-info']}>
          <div className={styles.row}>
            <Controller
              name="plate"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <Input
                  name={name}
                  label="Placa:"
                  value={value}
                  handleChange={onChange}
                  disabled
                  style={{ fontWeight: '600', color: '#000000' }}
                />
              )}
            />
            <Controller
              name="vehicle"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <Input
                  name={name}
                  label="Veículo:"
                  value={value}
                  handleChange={onChange}
                  disabled
                  style={{ fontWeight: '600', color: '#000000' }}
                />
              )}
            />
            <Controller
              name="chassi"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <Input
                  name={name}
                  label="Chassi:"
                  value={value}
                  handleChange={onChange}
                  disabled
                  style={{ fontWeight: '600', color: '#000000' }}
                />
              )}
            />
          </div>
          <div className={styles.row}>
            <Controller
              name="year"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <Input
                  name={name}
                  label="Ano:"
                  value={value}
                  handleChange={onChange}
                  disabled
                  style={{ fontWeight: '600', color: '#000000' }}
                />
              )}
            />
            <Controller
              name="model"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <Input
                  name={name}
                  label="Modelo:"
                  value={value}
                  handleChange={onChange}
                  disabled
                  style={{ fontWeight: '600', color: '#000000' }}
                />
              )}
            />
          </div>
          <Controller
            name="paymentMethod"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                label="Forma de Pagamento:"
                value={value}
                handleChange={onChange}
                disabled
                style={{ fontWeight: '600', color: '#000000' }}
              />
            )}
          />
          <div className={styles.observations}>
            <Controller
              name="observation"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <Textarea
                  name={name}
                  handleChange={onChange}
                  value={value}
                  label="Observações:"
                  disabled
                  style={{
                    height: '5rem',
                    fontWeight: '500',
                    color: '#000000',
                  }}
                />
              )}
            />
          </div>
        </div>
        <ViewItemQuotationList quotationAnswer={quotationAnswer} />

        <div
          className={`${styles.observations} ${styles.observations_with_margin_top}`}
        >
          <Textarea
            name="comment"
            handleChange={() => console.log()}
            label="Comentário do fornecedor:"
            value={quotationAnswer?.comment ?? ''}
            disabled
            style={{
              height: '5rem',
              fontWeight: '500',
              color: '#000000',
            }}
          />
          <Textarea
            name="rebuttal"
            handleChange={() => console.log()}
            label="Resposta da Oficina:"
            value={quotationAnswer?.rebuttal ?? ''}
            disabled
            style={{
              height: '5rem',
              fontWeight: '500',
              color: '#000000',
            }}
          />
        </div>
      </Modal>
    </>
  );
};
