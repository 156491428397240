import React from 'react';
import { PageLayout } from '../../../shared/PageLayout';
import ChangePassword from '../../AgentGasStation/pages/ChangePassword';

export const Profile = () => {
  return (
    <PageLayout.Root title="Meu Perfil">
      <ChangePassword />
    </PageLayout.Root>
  );
};
