import { Banner } from '../../@types/interface';
import imgPlaceholder from '../../assets/img/image-placeholder.svg';
import { Modal } from '../Modal';
import styles from './banner-view-modal.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  banner: Banner | null;
}

export const BannerViewModal = ({ isOpen, onClose, banner }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={banner?.title ?? ''}>
      <div className={styles.container}>
        <img
          src={(banner?.img_url || imgPlaceholder) ?? ''}
          alt={banner?.title ?? ''}
        />
      </div>
    </Modal>
  );
};
