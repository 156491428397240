import React, { useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Modal } from '../../../../shared/Modal';
import { Input } from '../../../../shared/Input';
import styles from './agent-view-modal.module.css';
import logo from '../../../../assets/icons/autocenter_logo.svg';
import { maskCpfCnpj } from '../../../../services/helpers/mask';
import { User } from '../../../../@types/User';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: User;
}

export const AgentViewModal = ({ isOpen, onClose, data }: Props) => {
  const [disabledEditableAgent, setDisabledEditableAgent] = useState(true);
  const [editableCPF, setEeditableCPF] = useState('');
  const [editableName, setEditableName] = useState('');
  const [buttonSave, setButtonSave] = useState(false);

  useEffect(() => {
    if (editableCPF.length > 0 && editableName.length > 0) {
      setButtonSave(true);
    }
  }, [editableCPF, editableName]);

  return (
    <Modal title="Código de vendedor" isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <Input
          label="CPF"
          name="cpf"
          disabled={disabledEditableAgent}
          onChange={(e: any) => setEeditableCPF(e)}
          value={maskCpfCnpj(data.document)}
        />
        <Input
          label="Nome"
          name="name"
          disabled={disabledEditableAgent}
          onChange={(e: any) => setEditableName(e)}
          value={data.name}
        />

        <QRCodeSVG
          value={data.agents[0]?.code ?? '0000'}
          size={200}
          imageSettings={{
            src: logo,
            height: 24,
            width: 24,
            excavate: true,
          }}
        />
      </div>
    </Modal>
  );
};
