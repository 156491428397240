import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  approveQuotationController,
  getQuotationByIdController,
} from '../../../controller';
import {
  removeAnswer,
  setApprovedQuotationList,
  updateAnsweredQuotation,
} from '../../../../../../../features/quotation/quotation-reply-slice';
import { useAppSelector } from '../../../../../../../hooks/useAppSelector';
import { numberOnly } from '../../../../../../../services/helpers/mask';
import { sendWhatsappMessage } from '../../../../../../../utils/sendWhatsappMessage';
import { QuotationAnswer } from '../../../interface';
import {
  Quotation,
  AnswerQuotation,
} from '../../../../../../../@types/interface';

interface Props {
  selectedQuotation?: Quotation | null;
  isOpen: boolean;
  onClose: () => void;
}

export const useViewReplies = ({
  selectedQuotation = null,
  isOpen,
  onClose,
}: Props) => {
  const [quotationReplies, setQuotationReplies] = useState<Quotation | null>(
    selectedQuotation,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [modalStatus, setModalStatus] = useState<
    'sending' | 'success' | 'error'
  >('sending');
  const [isApprovingModalOpen, setIsApprovingModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');
  const [answeredQuotationId, setAnsweredQuotationId] = useState<string>('');
  const [shouldFinishModal, setShouldFinishModal] = useState(false);
  const [isTrembleWarning, setIsTrembleWarning] = useState(false);
  const [selectedQuotationResponse, setSelectedQuotationResponse] =
    useState<Quotation | null>(null);

  const dispatch = useDispatch();
  const { approvedQuotation } = useAppSelector(state => state.quotationReducer);
  const fetchQuotationById = useCallback(
    async (id: string) => {
      setIsLoading(true);
      const res = await getQuotationByIdController(id);
      if (res) {
        setSelectedQuotationResponse(res);
        dispatch(
          setApprovedQuotationList({
            list_answers_approved:
              res.answers_quotation
                ?.filter(answer => answer.answered)
                ?.filter(answer => !answer.confirmed)
                ?.map(answer => ({
                  answer_quotation_id: answer.id_answer_quotation,
                  rebuttal: '',
                  items: [],
                })) || [],
            quotation_id: res.id_quotation,
          }),
        );
        setQuotationReplies(res);
        // set quotation as visualized
        dispatch(updateAnsweredQuotation(res));
      }
      setIsLoading(false);
    },
    [dispatch],
  );

  const handleApproveQuotation = async () => {
    if (approvedQuotation) {
      setIsApprovingModalOpen(true);
      setModalStatus('sending');

      const formattedAnswers = approvedQuotation.list_answers_approved.map(
        answer => ({
          ...answer,
          items: answer.items
            .map(item => ({
              item_answer_quotation_id: item.item_answer_quotation_id,
              quantity_asked: item.quantity_asked,
            }))
            .filter(item => item.quantity_asked > 0),
        }),
      );

      const res = await approveQuotationController({
        ...approvedQuotation,
        list_answers_approved: formattedAnswers,
      });
      if (res.status === 'success') {
        setAnsweredQuotationId((res.data as QuotationAnswer[])[0].quotation_id);
        setModalStatus('success');
        // if (typeof res.data === 'object') {
        //   // Verify if this quotation can receive more answers
        //   if (
        //     approvedQuotation.list_answers_approved.length <
        //     QUOTATION.MAX_QUOTATION_ANSWERS
        //   ) {
        //     if (
        //       approvedQuotation.list_answers_approved.length <
        //       (selectedQuotationResponse?.answers_quotation?.length || 0)
        //     ) {
        //       setShouldFinishModal(true);
        //     }
        //   }
        // }
        handleCloseModal();
        onClose();
      } else {
        setErrorModalMessage(res.data as string);
        setModalStatus('error');
      }
    }
  };

  const handleApproveAnswerById = async (idAnswer: string) => {
    if (approvedQuotation) {
      setIsApprovingModalOpen(true);
      setModalStatus('sending');

      const formattedAnswer = approvedQuotation.list_answers_approved
        .filter(answer => answer.answer_quotation_id === idAnswer)
        .map(answer => ({
          ...answer,
          items: answer.items
            .map(item => ({
              item_answer_quotation_id: item.item_answer_quotation_id,
              quantity_asked: item.quantity_asked,
            }))
            .filter(item => item.quantity_asked > 0),
        }));

      const res = await approveQuotationController({
        ...approvedQuotation,
        list_answers_approved: formattedAnswer,
      });
      if (res.status === 'success') {
        setAnsweredQuotationId((res.data as QuotationAnswer[])[0].quotation_id);
        setModalStatus('success');
        setIsApprovingModalOpen(false);
        dispatch(removeAnswer({ answer_quotation_id: idAnswer }));
        if (typeof res.data === 'object') {
          const updatedAnswersQuotation = res.data as AnswerQuotation[];
          const filteredAnswerQuotation =
            quotationReplies?.answers_quotation?.filter(
              answer => answer.id_answer_quotation !== idAnswer,
            ) || [];
          setQuotationReplies(previous => {
            if (!previous) return null;
            return {
              ...previous,
              answers_quotation: [
                ...filteredAnswerQuotation,
                ...updatedAnswersQuotation,
              ],
            };
          });
        }
      } else {
        setErrorModalMessage(res.data as string);
        setModalStatus('error');
        setIsApprovingModalOpen(false);
      }
    }
  };

  const handleRejectAnswerById = async (idAnswer: string) => {
    if (approvedQuotation) {
      setIsApprovingModalOpen(true);
      setModalStatus('sending');

      const formattedAnswer = approvedQuotation.list_answers_approved
        .filter(answer => answer.answer_quotation_id === idAnswer)
        .map(answer => ({
          ...answer,
          items: [],
        }));

      const res = await approveQuotationController({
        ...approvedQuotation,
        list_answers_approved: formattedAnswer,
      });
      if (res.status === 'success') {
        setAnsweredQuotationId((res.data as QuotationAnswer[])[0].quotation_id);
        setModalStatus('success');
        setIsApprovingModalOpen(false);
        dispatch(removeAnswer({ answer_quotation_id: idAnswer }));
        if (typeof res.data === 'object') {
          const updatedAnswersQuotation = res.data as AnswerQuotation[];
          const filteredAnswerQuotation =
            quotationReplies?.answers_quotation?.filter(
              answer => answer.id_answer_quotation !== idAnswer,
            ) || [];
          setQuotationReplies(previous => {
            if (!previous) return null;
            return {
              ...previous,
              answers_quotation: [
                ...filteredAnswerQuotation,
                ...updatedAnswersQuotation,
              ],
            };
          });
        }
      } else {
        setErrorModalMessage(res.data as string);
        setModalStatus('error');
        setIsApprovingModalOpen(false);
      }
    }
  };

  const handleCloseModal = () => {
    setIsApprovingModalOpen(false);
    setModalStatus('sending');
    setIsConfirmModalOpen(false);
    setAnsweredQuotationId('');
  };

  const closeErrorModal = () => {
    setErrorModalMessage('');
  };

  const handleConfirmModal = (option: 'open' | 'close', invalid?: boolean) => {
    if (option === 'open') {
      if (invalid !== true) {
        setIsTrembleWarning(false);
        return setIsConfirmModalOpen(true);
      }
      setIsTrembleWarning(true);
    } else {
      setIsConfirmModalOpen(false);
    }
  };
  const closeFinishModal = () => {
    setShouldFinishModal(false);
  };

  const handleInvalid = (invalid: boolean) => {
    if (!invalid) {
      setIsTrembleWarning(false);
    }
  };

  const handleWhatsappMessage = (
    phone: string,
    quotationNumber: Quotation | null,
  ) => {
    if (phone && quotationNumber && quotationNumber.QUOTATION) {
      const Text = `Olá, gostaria de falar sobre a cotação N° ${quotationNumber.QUOTATION}`;

      sendWhatsappMessage(Text, `${numberOnly(phone)}`);
    }
  };

  useEffect(() => {
    if (selectedQuotation && isOpen) {
      fetchQuotationById(selectedQuotation.id_quotation);
    }
    return () => {
      setQuotationReplies(null);
      setApprovedQuotationList(null);
    };
  }, [fetchQuotationById, isOpen, selectedQuotation]);

  useEffect(() => {
    if (quotationReplies && quotationReplies.answers_quotation) {
      const answersList = quotationReplies.answers_quotation
        .filter(answer => !answer.confirmed)
        .map(answer => ({
          answer_quotation_id: answer.id_answer_quotation,
          items: [],
          rebuttal: '',
        }));
      setApprovedQuotationList({
        quotation_id: quotationReplies.id_quotation,
        list_answers_approved: answersList,
      });
    }
  }, [quotationReplies]);

  return {
    quotationReplies,
    isLoading,
    handleApproveQuotation,
    modalStatus,
    handleCloseModal,
    isApprovingModalOpen,
    isConfirmModalOpen,
    handleConfirmModal,
    errorModalMessage,
    closeErrorModal,
    shouldFinishModal,
    closeFinishModal,
    answeredQuotationId,
    isTrembleWarning,
    handleInvalid,
    handleWhatsappMessage,
    handleApproveAnswerById,
    handleRejectAnswerById,
  };
};
