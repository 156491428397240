import { BrowserRouter } from 'react-router-dom';

import { useEffect, useState, Suspense, lazy } from 'react';
import Cookies from 'js-cookie';
import { isAuthenticated } from '../services/api/auth';
import { getUserData } from '../utils/workshopData';
import { CommonRoutes } from './common/routes';
import Fallback from '../shared/Fallback/Fallback';
import { User } from '../@types/User';

export const visitorId = 'f746c180-d2a2-47d3-9011-81b3b923beff';

const AgentGasStationRoutes = lazy(() => import('./AgentGasStation/routes'));
const QuotationAgentRoutes = lazy(() => import('./QuotationAgent/routes'));

const Routes = () => {
  const [userData, setUserData] = useState<User>();

  const permissions = (userData?.permissions ?? []).map(
    permission => permission.code,
  );
  const permissionType =
    permissions.includes('CREATE_QUOT') && permissions.includes('ANSWER_QUOT')
      ? 'quotation'
      : 'default';

  useEffect(() => {
    Object.keys(Cookies.get()).forEach(cookieName => {
      Cookies.remove(cookieName);
    });
    if (isAuthenticated()) {
      setUserData(() => getUserData());
    }
  }, []);

  const handleAccessType = (
    type: 'agentGasStation' | 'quotation' | 'default',
  ) => {
    switch (type) {
      case 'agentGasStation':
        return (
          <Suspense fallback={<Fallback />}>
            <AgentGasStationRoutes />
            <CommonRoutes />
          </Suspense>
        );
      case 'quotation':
        return (
          <Suspense fallback={<Fallback />}>
            <QuotationAgentRoutes />
            <CommonRoutes />
          </Suspense>
        );

      default:
        return (
          <Suspense fallback={<Fallback />}>
            <CommonRoutes />
          </Suspense>
        );
    }
  };

  return (
    <BrowserRouter>
      {handleAccessType(permissionType ? 'quotation' : 'default')}
    </BrowserRouter>
  );
};

export default Routes;
