import { AlertMessage } from '../../../../../../../shared/AlertMessage';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  container: Element | DocumentFragment;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmAnswerModal = ({
  container,
  isOpen,
  onCancel,
  onClose,
  onConfirm,
}: Props) => {
  return (
    <AlertMessage.Root
      isOpen={isOpen}
      onClose={onClose}
      container={container}
      disableScroll
      title="CONFIRMAR Proposta?"
      footer={
        <AlertMessage.Footer>
          <AlertMessage.CancelButton onClick={onCancel}>
            Revisar
          </AlertMessage.CancelButton>
          <AlertMessage.ConfirmButton onClick={onConfirm} />
        </AlertMessage.Footer>
      }
    >
      <AlertMessage.Body>
        Proposta enviada não poderá ser editada. Confirmar envio?
      </AlertMessage.Body>
    </AlertMessage.Root>
  );
};
