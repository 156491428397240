import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  createQuotationController,
  getQuotationByIdController,
} from '../../../controller';
import { addNotAnsweredQuotations } from '../../../../../../../features/quotation/quotation-reply-slice';

export interface Quote {
  description: string;
  reference: string;
  quantity: string;
}

interface CreateQuotationProps {
  items: Quote[];
  vehicleId: string;
  comment: string;
  imagesList: File[];
  hours?: string;
  type_payment?: string;
}

export const useCreateQuotation = () => {
  const [isCreating, setIsCreating] = useState(false);
  const dispatch = useDispatch();

  const createQuotation = async ({
    comment,
    items,
    vehicleId,
    imagesList,
    hours,
    type_payment,
  }: CreateQuotationProps) => {
    setIsCreating(true);
    const createQuotationRequestBody: CreateQuotationProps = {
      items,
      vehicleId,
      comment: comment ?? '',
      imagesList,
    };
    if (hours) createQuotationRequestBody.hours = hours;
    if (type_payment) createQuotationRequestBody.type_payment = type_payment;

    const res = await createQuotationController({
      ...createQuotationRequestBody,
    });

    if (res) {
      dispatch(addNotAnsweredQuotations([res]));
    }
    setIsCreating(false);
    return res;
  };

  const fetchQuotation = useCallback(async (id: string) => {
    const res = await getQuotationByIdController(id);
    return res;
  }, []);

  return {
    createQuotation,
    isCreating,
    fetchQuotation,
  };
};
