import {
  ArchiveBox,
  CheckSquare,
  CopySimple,
  LockSimple,
} from '@phosphor-icons/react';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageLayout } from '../../../../shared/PageLayout';
import { ApprovedQuotations } from './pages/ApprovedQuotations';
import { FinishedQuotations } from './pages/FinishedQuotations';
import { NotAnsweredQuotations } from './pages/NotAnsweredQuotations';

import { Circle } from '../../../../shared/Circle';
import { useQuotationAnswer } from './hooks/useQuotationAnswer';

import styles from './answer-quotation.module.css';
import { LittleHeaderAnswerQuotation } from './components/LittleHeaderAnswerQuotation';
import { useResponsive } from '../../../../hooks/useResponsive';
import { MissedQuotations } from './pages/MissedQuotations';
import {
  getObjectFromLocal,
  saveObjectLocally,
} from '../../../../utils/localStorage';
import { isAuthenticated } from '../../../../services/api/auth';

export const AnswerQuotation = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const answerId = queryParams.get('answerId');
  const localAnswerId = getObjectFromLocal('answerId');

  useEffect(() => {
    if (answerId && answerId.length > 0) {
      saveObjectLocally('answerId', answerId);
      if (isAuthenticated()) navigate('/answer-quotation');
      else navigate('/page-login');
    }
  }, [answerId, navigate]);

  useEffect(() => {
    if (localAnswerId && localAnswerId.length > 0) {
      setSelectedPage('notAnswered');
    }
  }, [localAnswerId, navigate]);

  const { notAnsweredQuotationList, notApprovedQuotationList } =
    useQuotationAnswer();

  const [selectedPage, setSelectedPage] = useState<
    'notAnswered' | 'approved' | 'finished' | 'missed'
  >('notAnswered');

  const renderSelectedPage = useMemo(() => {
    switch (selectedPage) {
      case 'notAnswered':
        return <NotAnsweredQuotations />;
      case 'approved':
        return <ApprovedQuotations />;
      case 'missed':
        return <MissedQuotations />;
      default:
        return <FinishedQuotations />;
    }
  }, [selectedPage]);

  const handleSelectedTitle = useMemo(() => {
    if (selectedPage === 'notAnswered') return 'Responder Cotações';
    if (selectedPage === 'missed') return 'cotações perdidas';
    if (selectedPage === 'approved') return 'cotações aprovadas';
    return 'cotações finalizadas';
  }, [selectedPage]);

  const { sizeMobile } = useResponsive();

  const notAnsweredQuotationCounter = notAnsweredQuotationList.filter(
    answer => !answer.answered,
  ).length;
  const notVisualizedNotApprovedQuotationList = notApprovedQuotationList.filter(
    answer => !answer.visualized && !answer?.quotation?.blocked,
  );

  const handleSwitchComponent = () => {
    if (!sizeMobile) {
      return (
        <>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'notAnswered'}
            onClick={() => setSelectedPage('notAnswered')}
          >
            <ArchiveBox size={32} weight="fill" />
            {notAnsweredQuotationCounter > 0 &&
              `(${notAnsweredQuotationCounter}) `}
            Recebidas
            {notAnsweredQuotationCounter > 0 && (
              <Circle animation="pulse" variant="notification" />
            )}
          </PageLayout.TopMenuButton>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'approved'}
            onClick={() => setSelectedPage('approved')}
          >
            {notVisualizedNotApprovedQuotationList.length > 0 &&
              `(${notVisualizedNotApprovedQuotationList.length})`}
            <CheckSquare size={32} weight="fill" />

            {notVisualizedNotApprovedQuotationList.length > 0
              ? 'Aguardando Confirmar Pedido'
              : 'Confirmar Pedido'}

            {notVisualizedNotApprovedQuotationList.length > 0 && (
              <Circle animation="pulse" variant="notification" />
            )}
          </PageLayout.TopMenuButton>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'missed'}
            onClick={() => setSelectedPage('missed')}
          >
            <LockSimple size={32} weight="fill" />
            Perdidas
          </PageLayout.TopMenuButton>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'finished'}
            onClick={() => setSelectedPage('finished')}
          >
            <CopySimple size={32} weight="fill" />
            Finalizadas
          </PageLayout.TopMenuButton>
        </>
      );
    }
    return (
      <LittleHeaderAnswerQuotation
        answeredQuotationList={notAnsweredQuotationList}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
      />
    );
  };

  const handleSwitchNavigate = () => {
    if (selectedPage === 'notAnswered') return navigate('/home');
    if (selectedPage === 'approved') return setSelectedPage('notAnswered');
    if (selectedPage === 'missed') return setSelectedPage('approved');
    if (selectedPage === 'finished') return setSelectedPage('missed');
  };

  return (
    <PageLayout.Root
      title={handleSelectedTitle}
      topMenu={handleSwitchComponent()}
      handleNavigation={() => {
        if (sizeMobile) {
          handleSwitchNavigate();
        } else {
          navigate('/home');
        }
      }}
    >
      {renderSelectedPage}
    </PageLayout.Root>
  );
};
