import { CSSProperties, ReactNode } from 'react';
import styles from './sub-row.module.css';

interface Props {
  children: ReactNode;
  style?: CSSProperties;
}

export const SubRow = ({ children, style }: Props) => {
  return (
    <div className={styles.subrow} style={style}>
      {children}
    </div>
  );
};
