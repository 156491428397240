import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { HeaderComponent } from '../../../HeaderComponent';
import styles from './root.module.css';
import { TopMenu } from '../TopMenu';

interface Props {
  children: ReactNode;
  navbar?: ReactNode;
  topMenu?: ReactNode;
  title: string;
  handleMenuClick?: () => void;
  handleNavigation?: () => void;
  style?: CSSProperties;
  sectionStyle?: CSSProperties;
}

export const Root = ({
  children,
  title,
  handleMenuClick,
  handleNavigation,
  navbar,
  topMenu,
  style,
  sectionStyle,
}: Props) => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 480);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleContentSize = () => {
    if (!navbar) return '90%';
    if (isSmallScreen) return '100%';
    return '80%';
  };

  return (
    <main className={styles.container} style={style}>
      <HeaderComponent
        title={title}
        handleMenuClick={
          navbar ? () => setIsNavbarOpen(prev => !prev) : handleMenuClick
        }
        handleNavigation={handleNavigation}
      />
      <TopMenu>{topMenu}</TopMenu>
      <section className={styles.section} style={sectionStyle}>
        {!isSmallScreen && navbar}
        {isSmallScreen && isNavbarOpen && navbar}
        <div className={styles.content} style={{ width: handleContentSize() }}>
          {children}
        </div>
      </section>
    </main>
  );
};
