import { useNavigate } from 'react-router-dom';
import arrowDiagonal from '../../../../../assets/icons/arrow-diagonal.svg';
import styles from './action-card.module.css';
import { Circle } from '../../../../../shared/Circle';

interface Props {
  icon: React.ReactNode;
  title: string;
  description: string;
  path: string;
  onClick?: () => void;
  notifications?: number;
}

export const ActionCard = ({
  description,
  icon,
  title,
  path,
  onClick,
  notifications = 0,
}: Props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(path);
    }
  };

  return (
    <div className={styles.container}>
      <button type="button" onClick={handleClick}>
        <div className={styles.content}>
          <div className={styles.icon}>
            {icon}
            {notifications > 0 && (
              <Circle
                animation="pulse"
                variant="notification"
                style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                }}
              />
            )}
          </div>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </div>
      </button>
      <div className={styles.pattern}>
        <img src={arrowDiagonal} className={styles.arrow} alt="" />
      </div>
    </div>
  );
};
