import { CSSProperties, ReactNode } from 'react';
import { CaretDown, CaretUp, IconWeight } from '@phosphor-icons/react';
import styles from './row.module.css';

interface Props {
  selected?: boolean;
  selectedWeight?: IconWeight;
  handleClick?: () => void;
  children: ReactNode;
  style?: CSSProperties;
  variant?: 'default' | 'caret';
}

export const Row = ({
  selected = false,
  selectedWeight = 'light',
  handleClick,
  children,
  style,
  variant = 'default',
}: Props) => {
  return (
    <button
      type="button"
      onClick={() => {
        if (handleClick) handleClick();
      }}
      className={styles.container}
    >
      <div
        className={`${styles.row} ${selected ? styles.selected : ''}`}
        style={style}
      >
        {variant === 'caret' && selected && (
          <CaretUp size={24} weight={selectedWeight} />
        )}
        {variant === 'caret' && !selected && (
          <CaretDown size={24} weight={selectedWeight} />
        )}
        {children}
      </div>
    </button>
  );
};
