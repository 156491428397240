import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../../../hooks/useAppSelector';
import { Quotation } from '../../../../../../../@types/interface';
import {
  getMyQuotationsController,
  getQuotationByIdController,
  increaseQuotationHoursController,
} from '../../../controller';
import { QUOTATION } from '../../../../../../../constants';
import { QuotationFilterOptions } from '../../../interface';

export const useCreatedQuotationData = () => {
  const { notAnsweredQuotationList } = useAppSelector(
    state => state.quotationReducer,
  );

  const [page, setPage] = useState(1);
  const [createdQuotations, setCreatedQuotations] = useState<Quotation[]>(
    notAnsweredQuotationList,
  );
  const [searchedQuotations, setSearchedQuotations] = useState<Quotation[]>(
    notAnsweredQuotationList,
  );
  const [searchedQuotation, setSearchedQuotation] = useState<Quotation | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);
  const [isIncrementingHours, setIsIncrementingHours] = useState(false);

  const fetchNotAnsweredQuotations = useCallback(
    async (pageRequest: number) => {
      setIsLoading(true);
      if (!noMoreData) {
        const res = await getMyQuotationsController({
          limit: QUOTATION.REQUEST_LIMIT,
          page: pageRequest,
          option: 'not_answered',
        });

        if (res && res.length > 0) {
          setCreatedQuotations(previous => {
            const newQuotations = res.filter(
              quotation =>
                !previous.some(
                  existingQuotation =>
                    quotation.id_quotation === existingQuotation.id_quotation,
                ),
            );
            return [...previous, ...newQuotations];
          });
        } else {
          setNoMoreData(true);
        }
      }
      setIsLoading(false);
    },
    [noMoreData],
  );

  const fetchQuotationsByFilter = useCallback(
    async (filterValue: string, filterType: QuotationFilterOptions) => {
      setIsLoading(true);

      if (filterValue.trim().length > 0) {
        const res = await getMyQuotationsController({
          option: 'not_answered',
          filter: filterType,
          filter_value: filterValue,
        });

        if (res && res.length > 0) {
          setSearchedQuotations(res);
        } else {
          setSearchedQuotations([]);
        }
      } else {
        setSearchedQuotations([]);
      }
      setIsLoading(false);
    },
    [],
  );

  const loadMoreData = useCallback(
    (previousPage = 1) => {
      if (!noMoreData) {
        fetchNotAnsweredQuotations(previousPage);
        setPage(previousPage + 1);
      }
    },
    [fetchNotAnsweredQuotations, noMoreData],
  );

  const handleFinishQuotation = (
    res: Quotation,
    onFinishQuotation?: () => void,
  ) => {
    setCreatedQuotations(previous =>
      previous.filter(quotation => quotation.id_quotation !== res.id_quotation),
    );
    if (onFinishQuotation) onFinishQuotation();
  };

  const fetchQuotationById = useCallback(async (quotationId: string) => {
    const res = await getQuotationByIdController(quotationId);

    if (res) {
      setSearchedQuotation(res);
    }
  }, []);

  const handleIncrementHours = async (quotation: Quotation, hours: number) => {
    setIsIncrementingHours(true);
    const res = await increaseQuotationHoursController({
      quotation_id: quotation.id_quotation,
      increment: hours,
    });

    if (res) {
      setCreatedQuotations(previous =>
        previous.map(previousQuotation => {
          if (previousQuotation.id_quotation === res.id_quotation) {
            return res;
          }
          return previousQuotation;
        }),
      );
    }
    setIsIncrementingHours(false);
  };

  useEffect(() => {
    loadMoreData();
  }, [loadMoreData]);

  useEffect(() => {
    if (notAnsweredQuotationList) {
      setCreatedQuotations(notAnsweredQuotationList);
    }
  }, [notAnsweredQuotationList]);

  return {
    page,
    isLoading,
    handleFinishQuotation,
    fetchQuotationById,
    fetchQuotationsByFilter,
    searchedQuotation,
    searchedQuotations,
    createdQuotations,
    loadMoreData,
    setSearchedQuotation,
    handleIncrementHours,
    isIncrementingHours,
  };
};
