import { CopySimple, FileText, GridFour } from '@phosphor-icons/react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from '../../../../shared/PageLayout';
import { AnsweredQuotations } from './pages/AnsweredQuotations';
import { FinishedQuotations } from './pages/FinishedQuotations';
import { useQuotation } from './hooks/useQuotation';
import { LittleHeaderCreateQuotation } from './components/LittleHeaderCreateQuotation';
import { CreatedQuotations } from './pages/CreatedQuotations';
import { Circle } from '../../../../shared/Circle';
import { useResponsive } from '../../../../hooks/useResponsive';
import styles from './create-quotation.module.css';

export const CreateQuotation = () => {
  const { answeredQuotationList } = useQuotation();
  const { sizeMobile } = useResponsive();
  const navigate = useNavigate();

  const [selectedPage, setSelectedPage] = useState<
    'created' | 'replies' | 'completed'
  >('created');

  const renderSelectedPage = useMemo(() => {
    switch (selectedPage) {
      case 'created':
        return <CreatedQuotations />;
      case 'replies':
        return <AnsweredQuotations />;
      default:
        return <FinishedQuotations />;
    }
  }, [selectedPage]);

  const handleSelectedTitle = useMemo(() => {
    if (selectedPage === 'created') return 'Cotações';
    if (selectedPage === 'replies') return 'Cotações Respondidas';
    return 'Cotações Finalizadas';
  }, [selectedPage]);

  const notVisualizedAnsweredQuotations = answeredQuotationList.filter(
    answer => !answer.visualized,
  );

  const renderTopMenuButtons = () => {
    if (!sizeMobile) {
      return (
        <>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'created'}
            onClick={() => setSelectedPage('created')}
          >
            <GridFour size={32} weight="fill" />
            Criadas
          </PageLayout.TopMenuButton>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'replies'}
            onClick={() => {
              setSelectedPage('replies');
            }}
          >
            <FileText size={32} weight="fill" />
            {notVisualizedAnsweredQuotations.length > 0 &&
              `(${notVisualizedAnsweredQuotations.length})`}{' '}
            Respondidas
            {notVisualizedAnsweredQuotations.length > 0 && (
              <Circle animation="pulse" variant="notification" />
            )}
          </PageLayout.TopMenuButton>

          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'completed'}
            onClick={() => setSelectedPage('completed')}
          >
            <CopySimple size={32} weight="fill" />
            Finalizadas
          </PageLayout.TopMenuButton>
        </>
      );
    }
    return (
      <LittleHeaderCreateQuotation
        answeredQuotationList={notVisualizedAnsweredQuotations}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
      />
    );
  };

  const navigateBetweenPages = () => {
    if (selectedPage === 'created') return navigate('/home');
    if (selectedPage === 'replies') return setSelectedPage('created');
    if (selectedPage === 'completed') return setSelectedPage('replies');
  };

  return (
    <PageLayout.Root
      title={handleSelectedTitle}
      topMenu={renderTopMenuButtons()}
      handleNavigation={() => {
        if (sizeMobile) {
          navigateBetweenPages();
        } else {
          navigate('/home');
        }
      }}
    >
      {renderSelectedPage}
    </PageLayout.Root>
  );
};
