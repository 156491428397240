import { CSSProperties, ReactNode } from 'react';
import styles from './head.module.css';

interface Props {
  children: ReactNode;
  style?: CSSProperties;
}

export const Head = ({ children, style }: Props) => {
  return (
    <div className={styles.header} style={style}>
      {children}
    </div>
  );
};
