import { AlertMessage } from '../../../../../../../shared/AlertMessage';
import { AnswerQuotation } from '../../../../../../../@types/interface';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  onApprove: () => void;
  answer?: AnswerQuotation | null;
  hasNoApprovedItems: boolean;
  container: Element | DocumentFragment;
}

export const ApproveAnswerModal = ({
  isOpen,
  onClose,
  isLoading = false,
  onApprove,
  answer = null,
  hasNoApprovedItems,
  container,
}: Props) => {
  return (
    <AlertMessage.Root
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      title="confirmar aprovação"
      container={container}
      footer={
        <AlertMessage.Footer>
          {!isLoading && (
            <>
              <AlertMessage.CancelButton onClick={onClose}>
                Revisar
              </AlertMessage.CancelButton>
              <AlertMessage.ConfirmButton onClick={onApprove}>
                Aprovar Proposta
              </AlertMessage.ConfirmButton>
            </>
          )}
        </AlertMessage.Footer>
      }
    >
      <AlertMessage.Body>
        <p
          style={{
            textAlign: 'center',
          }}
        >
          Deseja aprovar a proposta de
          <b>{answer && ` ${answer?.workshop?.fantasy_name ?? ''}`}</b>?
          {hasNoApprovedItems && (
            <sub
              style={{
                color: 'var(--error)',
              }}
            >
              <b>Nenhum item foi selecionado</b>
            </sub>
          )}
        </p>
      </AlertMessage.Body>
    </AlertMessage.Root>
  );
};
