import { Control, Controller, useFieldArray } from 'react-hook-form';
import { useRef, useState } from 'react';
import { ProductTable } from '../../../../../../../../../shared/ProductTable';
import styles from './answer.module.css';
import { QuoteItem } from '../QuoteItem';
import {
  FormValues,
  SubItem,
  formItemQuotation,
} from '../../useAnswerQuotation';
import { Button } from '../../../../../../../../../shared/Button';
import { ConfirmModal } from '../ConfirmModal';
import { Textarea } from '../../../../../../../../../shared/Textarea';
import { RejectModal } from '../RejectModal';
import { AnswerQuotation } from '../../../../../../../../../@types/interface';
import { rejectQuotationController } from '../../../../../controller';

interface Props {
  control: Control<FormValues, any>;
  handleAnswer: () => void;
  formQuotationItems: formItemQuotation[];
  addSubItem: (index: number) => void;
  removeSubItem: (indexItem: number, indexSubitem: number) => void;
  answer: AnswerQuotation | null;
  onClose: () => void;
}

export const Answer = ({
  control,
  handleAnswer,
  formQuotationItems,
  addSubItem,
  removeSubItem,
  answer,
  onClose,
}: Props) => {
  const { fields: quotesItems } = useFieldArray({ control, name: 'items' });
  const [selectedRowId, setSelectedRowId] = useState('');
  const [confirmModalItems, setConfirmModalItems] = useState<number[]>([]);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const checkFormItems = () => {
    return formQuotationItems
      .map((item, itemIndex) => {
        // Check if  all subitems are invalid
        const allSubItemsInvalid = item.sub_items.every(subitem => {
          const quantityAvailable = parseFloat(
            subitem?.quantity_available ?? '0',
          );
          const total = parseFloat(subitem?.total ?? '0');
          return (
            isNaN(quantityAvailable) ||
            isNaN(total) ||
            quantityAvailable <= 0 ||
            total <= 0
          );
        });

        return allSubItemsInvalid ? itemIndex + 1 : -1;
      })
      .filter(index => index !== -1); // return only invalid indexes
  };

  const handleAnswerQuotation = () => {
    const invalidItemsIndex = checkFormItems();
    if (invalidItemsIndex.length === 0) {
      handleAnswer();
    } else {
      setConfirmModalItems(invalidItemsIndex);
    }
  };

  const isSubItemAnswered = (subItem: SubItem) => {
    const quantityAvailable = parseFloat(subItem?.quantity_available ?? '0');
    const total = parseFloat(subItem?.total ?? '0');
    return quantityAvailable > 0 && total > 0;
  };

  const handleConfirmRejection = async (comment: string) => {
    const res = await rejectQuotationController({
      id: answer?.id_answer_quotation ?? '',
      comment,
    });

    setIsRejectModalOpen(false);
    onClose();
  };

  return (
    <>
      <ConfirmModal
        container={containerRef.current || document.body}
        items={confirmModalItems}
        onClose={() => setConfirmModalItems([])}
        onConfirm={handleAnswer}
      />
      <RejectModal
        answer={answer}
        container={containerRef.current || document.body}
        isOpen={isRejectModalOpen}
        onClose={() => {
          setIsRejectModalOpen(false);
        }}
        onConfirm={(comment: string) => {
          handleConfirmRejection(comment);
        }}
      />
      <div className={styles.container} ref={containerRef}>
        <ProductTable.Root>
          <ProductTable.Body>
            {quotesItems.map((item, index) => (
              <QuoteItem
                key={item.id}
                item={item}
                control={control}
                index={index}
                isSelected={item.item_quotation_id === selectedRowId}
                onClick={() =>
                  setSelectedRowId(previous => {
                    if (item.item_quotation_id === previous) {
                      return '';
                    }
                    return item.item_quotation_id;
                  })
                }
                isAnswered={formQuotationItems
                  .filter(
                    formItem =>
                      formItem &&
                      formItem.sub_items.filter(subItem =>
                        isSubItemAnswered(subItem),
                      ).length > 0,
                  )
                  .some(
                    formItem =>
                      formItem.item_quotation_id === item.item_quotation_id,
                  )}
                addSubItem={addSubItem}
                removeSubItem={removeSubItem}
              />
            ))}
          </ProductTable.Body>
          <Controller
            control={control}
            name="comment"
            render={({ field: { name, onChange, value } }) => (
              <Textarea
                name={name}
                label="Comentários:"
                handleChange={onChange}
                value={value}
                style={{
                  marginBottom: '.5rem',
                  fontWeight: '500',
                  color: '#000000',
                }}
              />
            )}
          />
        </ProductTable.Root>
        <div className={styles.actions}>
          <Button
            handleClick={() => setIsRejectModalOpen(true)}
            variant="cancel"
          >
            Rejeitar
          </Button>
          <Button
            handleClick={handleAnswerQuotation}
            variant="register"
            style={{
              fontSize: '2rem',
              marginTop: 'auto',
              alignSelf: 'center',
            }}
          >
            Enviar Proposta
          </Button>
        </div>
      </div>
    </>
  );
};
