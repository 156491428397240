import { useCallback, useEffect, useState } from 'react';
import { Quotation } from '../../../../../../@types/interface';
import { getMyQuotationsController } from '../../controller';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';

const LIMIT = 80;

export const useAnsweredQuotations = () => {
  const { answeredQuotationList } = useAppSelector(
    state => state.quotationReducer,
  );
  // const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [quotationReplies, setQuotationReplies] = useState<Quotation[]>(
    answeredQuotationList,
  );
  const [searchedQuotations, setSearchedQuotations] = useState<Quotation[]>(
    answeredQuotationList,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);
  const [selectedQuotation, setSelectedQuotation] = useState<Quotation | null>(
    null,
  );
  const [isViewAnswersQuotationModalOpen, setIsViewAnswersQuotationModalOpen] =
    useState(false);
  const [showBlockedQuotationMessage, setShowBlockedQuotationMessage] =
    useState(false);
  const [filterType, setFilterType] = useState<'code' | 'date'>('code');
  const [filterValue, setFilterValue] = useState('');

  const fetchNotAnsweredQuotations = useCallback(
    async (pageRequest: number) => {
      setIsLoading(true);
      if (!noMoreData) {
        const res = await getMyQuotationsController({
          limit: LIMIT,
          page: pageRequest,
          option: 'answered',
        });

        if (res && res.length > 0) {
          setQuotationReplies(previous => {
            const newQuotations = res.filter(
              quotation =>
                !previous.some(
                  existingQuotation =>
                    quotation.id_quotation === existingQuotation.id_quotation,
                ),
            );
            return [...previous, ...newQuotations];
          });
        } else {
          setNoMoreData(true);
        }
      }
      setIsLoading(false);
    },
    [noMoreData],
  );

  const loadMoreData = useCallback(
    (previousPage = 1) => {
      if (!noMoreData) {
        fetchNotAnsweredQuotations(previousPage);
        setPage(previousPage + 1);
      }
    },
    [fetchNotAnsweredQuotations, noMoreData],
  );

  useEffect(() => {
    loadMoreData();
  }, [loadMoreData]);
  useEffect(() => {
    if (answeredQuotationList) {
      setQuotationReplies(answeredQuotationList);
    }
  }, [answeredQuotationList]);

  const handleRowClick = (quotation: Quotation) => {
    if (
      !selectedQuotation ||
      selectedQuotation.id_quotation !== quotation.id_quotation
    ) {
      setSelectedQuotation(quotation);
    } else {
      setSelectedQuotation(null);
    }
  };

  const handleShowBlockedQuotationMessage = (option: 'close' | 'open') => {
    if (option === 'open') setShowBlockedQuotationMessage(true);
    else setShowBlockedQuotationMessage(false);
  };

  // const handleBlockModal = (option: 'close' | 'open') => {
  //   if (option === 'open') setIsBlockModalOpen(true);
  //   else setIsBlockModalOpen(false);
  // };

  const handleChangeQuotationState = (quotation: Quotation) => {
    setQuotationReplies(previous =>
      previous.filter(
        repliedQuotation =>
          repliedQuotation.id_quotation !== quotation.id_quotation,
      ),
    );
    setSelectedQuotation(null);
  };

  const fetchQuotationsByFilter = useCallback(async () => {
    setIsLoading(true);

    if (filterValue.trim().length > 0) {
      const res = await getMyQuotationsController({
        option: 'answered',
        filter: filterType,
        filter_value: filterValue,
      });

      if (res && res.length > 0) {
        setSearchedQuotations(res);
      } else {
        setSearchedQuotations([]);
      }
    } else {
      setSearchedQuotations([]);
    }
    setIsLoading(false);
  }, [filterType, filterValue]);

  const handleDateSearch = (period: string[]) => {
    // clear search state for invalid period
    if (period.length !== 2) {
      setFilterValue('');
    } else {
      const [initialDate, finalDate] = period;
      if (initialDate.trim().length > 0 && finalDate.trim().length > 0) {
        setFilterValue(`["${initialDate}", "${finalDate}"]`);
      }
    }
  };
  const handleSearch = (query: string) => {
    setFilterValue(query);
  };

  useEffect(() => {
    if (filterValue.trim().length > 0) {
      fetchQuotationsByFilter();
    }
  }, [fetchQuotationsByFilter, filterValue]);

  return {
    // isBlockModalOpen,
    // handleBlockModal,
    page,
    quotationReplies,
    isLoading,
    noMoreData,
    loadMoreData,
    selectedQuotation,
    setSelectedQuotation,
    isViewAnswersQuotationModalOpen,
    setIsViewAnswersQuotationModalOpen,
    handleRowClick,
    fetchNotAnsweredQuotations,
    handleShowBlockedQuotationMessage,
    showBlockedQuotationMessage,
    handleChangeQuotationState,
    handleDateSearch,
    handleSearch,
    filterType,
    filterValue,
    searchedQuotations,
    setFilterType,
  };
};
