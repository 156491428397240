import { AnswerQuotation } from '../../../../@types/interface';
import api from '../../../../services/api/api';
import {
  AnswerQuotationInput,
  GetAllMyAnswerResponse,
  GetMyAnswerProps,
  UpdateAnswerQuotationWatchStateInput,
} from './interface';

export const getMyAnswers = async ({
  limit,
  option,
  page,
  filter,
  filter_value,
}: GetMyAnswerProps) => {
  const params: Partial<GetMyAnswerProps> = {};
  if (limit) params.limit = limit;
  if (page) params.page = page;
  if (option) params.option = option;
  if (filter) params.filter = filter;
  if (filter_value) params.filter_value = filter_value;

  const { data } = await api.get<AnswerQuotation[]>('/quotation/answer/agent', {
    params,
  });

  return data;
};

export const getAllMyAnswers = async ({
  limit,
  page,
}: Omit<GetMyAnswerProps, 'option'>) => {
  const params: Partial<GetMyAnswerProps> = {};
  if (limit) params.limit = limit;
  if (page) params.page = page;

  const { data } = await api.get<GetAllMyAnswerResponse>(
    '/quotation/answer/agent',
    {
      params,
    },
  );

  return data;
};

export const answerQuotation = async (props: AnswerQuotationInput) => {
  const { data } = await api.post<AnswerQuotation>('/quotation/answer', {
    ...props,
  });

  return data;
};

export const confirmQuotation = async (id: string) => {
  const { data } = await api.post<AnswerQuotation>(
    `/quotation/answer/confirme/${id}`,
  );
  return data;
};

export const getAnswerQuotationById = async (id: string) => {
  const { data } = await api.get<AnswerQuotation>(`/quotation/answer/${id}`);

  return data;
};
export const updateAnswerQuotationWatchState = async (
  props: UpdateAnswerQuotationWatchStateInput,
) => {
  const { data } = await api.put<AnswerQuotation>(
    `/quotation/answer/watching/${props.id}?watch_state=${props.state}`,
  );

  return data;
};

export const rejectQuotation = async (
  quotationId: string,
  comment?: string,
) => {
  if (comment) {
    const { data } = await api.put<AnswerQuotation>(
      `/quotation/answer/reject/${quotationId}`,
      {
        comment,
      },
    );
    return data;
  }
  const { data } = await api.put<AnswerQuotation>(
    `/quotation/answer/reject/${quotationId}`,
  );
  return data;
};
