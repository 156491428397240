import { useCallback, useEffect, useState } from 'react';

interface Props {
  initialDate: Date;
  expirationHours: number;
}

export const useCountdownTimer = ({ initialDate, expirationHours }: Props) => {
  const [timer, setTimer] = useState('00:00:00');

  const handleExpirationTime = (createdAt: Date, hours: number) => {
    const createdAtTime = new Date(createdAt).getTime();
    const restingHours = hours * 60 * 60 * 1000;

    return createdAtTime + restingHours;
  };

  const handleTimer = useCallback(() => {
    const finalTime = handleExpirationTime(initialDate, expirationHours);
    const now = new Date().getTime();
    const remainingTime = finalTime - now;
    if (remainingTime > 0) {
      const hours = Math.floor(remainingTime / (60 * 60 * 1000));
      const minutes = Math.floor(
        (remainingTime % (60 * 60 * 1000)) / (60 * 1000),
      );
      const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);
      setTimer(
        `${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
      );
    } else {
      setTimer('expired');
    }
  }, [expirationHours, initialDate]);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      handleTimer();
    }, 1000);
    return () => {
      handleTimer();
      clearInterval(countdownInterval);
    };
  }, [handleTimer]);

  return {
    timer,
  };
};
