import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserData } from '../../../utils/workshopData';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { visitorId } from '../../../routes/routes';
import {
  getObjectFromLocal,
  removeObjectFromLocal,
} from '../../../utils/localStorage';

export const useHomeData = () => {
  const user = getUserData();
  const [isVisitor, setIsVisitor] = useState(false);
  const [isAnswerQuotationModalOpen, setIsAnswerQuotationModalOpen] =
    useState(false);

  const newQuotations = useAppSelector(
    state => state.quotationReducer.answeredQuotationList,
  ).filter(quotation => !quotation.visualized);

  const newAnswerQuotations = [
    ...useAppSelector(
      state => state.answerQuotationReducer.notApprovedQuotationList,
    ).filter(answer => !answer.visualized && !answer.quotation?.blocked),
    ...useAppSelector(state =>
      state.answerQuotationReducer.notAnsweredQuotationList.filter(
        answer =>
          !answer.visualized && !answer.quotation?.blocked && !answer.answered,
      ),
    ),
  ];

  useEffect(() => {
    if (user && user.id_user === visitorId) {
      setIsVisitor(true);
    }
  }, [user]);

  const navigate = useNavigate();

  const localAnswerId = getObjectFromLocal('answerId');

  useEffect(() => {
    if (localAnswerId && localAnswerId.length > 0) {
      setIsAnswerQuotationModalOpen(true);
    }
  }, [localAnswerId, setIsAnswerQuotationModalOpen]);

  const handleSingOut = () => {
    localStorage.clear();
    navigate('/');
    navigate(0);
  };

  const handleAnswerQuotation = () => {
    navigate('/answer-quotation');
  };

  const handleCancelAnswerQuotation = () => {
    removeObjectFromLocal('answerId');
    setIsAnswerQuotationModalOpen(false);
  };

  useEffect(() => {
    if (localStorage.getItem('reload')) {
      localStorage.removeItem('reload');
      window.location.reload();
    }

    if (user.id_user === visitorId) {
      setIsVisitor(true);
    }
  }, [user.id_user]);

  return {
    isVisitor,
    newQuotations,
    newAnswerQuotations,
    handleSingOut,
    navigate,
    isAnswerQuotationModalOpen,
    handleAnswerQuotation,
    handleCancelAnswerQuotation,
  };
};
