import { HtmlHTMLAttributes, ReactNode, forwardRef } from 'react';
import styles from './overlay.module.css';

interface Props extends HtmlHTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const Overlay = forwardRef<HTMLDivElement, Props>(
  ({ children, ...rest }, ref) => {
    return (
      <div className={styles.overlay} {...rest} ref={ref}>
        {children}
      </div>
    );
  },
);
