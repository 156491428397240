import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { getAllMyAnswersController } from '../controller';
import {
  setNotAnsweredQuotationList,
  setNotConfirmedQuotations,
  setFinishedQuotations,
  setRejectedQuotations,
} from '../../../../../features/answerQuotation/answer-quotation';
import { useResponsive } from '../../../../../hooks/useResponsive';
import {
  formatDate,
  dateOffset,
  formatDateToMobile,
} from '../../../../../utils/dateFunctions';
import { QUOTATION } from '../../../../../constants';

let isAnswerIntervalCreated = false;

export const useQuotationAnswer = () => {
  const dispatch = useDispatch();
  const { sizeMobile } = useResponsive();
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const { notApprovedQuotationList, notAnsweredQuotationList } = useAppSelector(
    state => state.answerQuotationReducer,
  );

  const fetchQuotationsAnswers = useCallback(async () => {
    const res = await getAllMyAnswersController({
      page: 1,
      limit: QUOTATION.REQUEST_LIMIT,
    });

    if (res) {
      const { finished, not_confirmed, recieved, rejected } = res;
      dispatch(setNotAnsweredQuotationList(recieved));
      dispatch(setNotConfirmedQuotations(not_confirmed));
      dispatch(setFinishedQuotations(finished));
      dispatch(setRejectedQuotations(rejected));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isAnswerIntervalCreated) {
      // first request
      fetchQuotationsAnswers();

      // define request interval
      isAnswerIntervalCreated = true;
      intervalRef.current = setInterval(
        fetchQuotationsAnswers,
        QUOTATION.TIME_UPDATE,
      );
    }

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        isAnswerIntervalCreated = false;
      }
    };
  }, [fetchQuotationsAnswers]);

  const handleShowDate = (date: Date | string) => {
    if (date && !sizeMobile) {
      return formatDate(dateOffset(new Date(date)));
    }
    if (date && sizeMobile) {
      return formatDateToMobile(dateOffset(new Date(date)));
    }
    return '---';
  };

  return {
    notApprovedQuotationList,
    notAnsweredQuotationList,
    fetchQuotationsAnswers,
    handleShowDate,
  };
};
