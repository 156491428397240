import { useCallback, useEffect, useState } from 'react';
import { AnswerQuotation, Quotation } from '../../../../../../@types/interface';
import { getMyQuotationsController } from '../../controller';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import { getAnswerQuotationByIdController } from '../../../AnswerQuotation/controller';

const LIMIT = 80;

export const useFinishedQuotation = () => {
  const { finishedQuotationList } = useAppSelector(
    state => state.quotationReducer,
  );
  const [page, setPage] = useState(1);
  const [finishedQuotations, setFinishedQuotations] = useState<Quotation[]>(
    finishedQuotationList,
  );
  const [searchedQuotations, setSearchedQuotations] =
    useState<Quotation[]>(finishedQuotations);
  const [isLoading, setIsLoading] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);
  useState<Quotation | null>(null);
  const [selectedQuotation, setSelectedQuotation] = useState<Quotation | null>(
    null,
  );
  const [isViewQuotationModalOpen, setIsViewQuotationModalOpen] =
    useState(false);
  const [filterType, setFilterType] = useState<'code' | 'date'>('code');
  const [filterValue, setFilterValue] = useState('');
  const [quotationReplies, setQuotationReplies] = useState<Quotation | null>(
    null,
  );
  const [searchedQuotationAnswer, setSearchedQuotationAnswer] =
    useState<AnswerQuotation | null>(null);

  const fetchFinishedQuotations = useCallback(
    async (pageRequest: number) => {
      setIsLoading(true);
      if (!noMoreData) {
        const res = await getMyQuotationsController({
          limit: LIMIT,
          page: pageRequest,
          option: 'finished',
        });

        if (res && res.length > 0) {
          setFinishedQuotations(previous => {
            const newQuotations = res.filter(
              quotation =>
                !previous.some(
                  existingQuotation =>
                    quotation.id_quotation === existingQuotation.id_quotation,
                ),
            );
            return [...previous, ...newQuotations];
            // .sort(
            //   (a, b) =>
            //     new Date(b.created_at).getTime() -
            //     new Date(a.created_at).getTime(),
            // );
          });
        } else {
          setNoMoreData(true);
        }
      }
      setIsLoading(false);
    },
    [noMoreData],
  );

  const loadMoreData = useCallback(
    (previousPage = 1) => {
      if (!noMoreData) {
        fetchFinishedQuotations(previousPage);
        setPage(previousPage + 1);
      }
    },
    [fetchFinishedQuotations, noMoreData],
  );

  useEffect(() => {
    loadMoreData();
  }, [loadMoreData]);

  useEffect(() => {
    setFinishedQuotations(previous => {
      const newQuotations = finishedQuotationList.filter(
        quotation =>
          !previous.some(
            existingQuotation =>
              quotation.id_quotation === existingQuotation.id_quotation,
          ),
      );
      return [...previous, ...newQuotations];
      // .sort(
      //   (a, b) =>
      //     new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      // );
    });
  }, [finishedQuotationList]);

  const handleRowClick = (quotation: Quotation) => {
    const { answers_quotation: selectedAnswers } = selectedQuotation || {};
    const { answers_quotation: currentAnswers } = quotation || {};

    const isDifferentQuotation =
      selectedAnswers &&
      currentAnswers &&
      selectedAnswers[0].id_answer_quotation !==
        currentAnswers[0].id_answer_quotation;

    if (!selectedQuotation || isDifferentQuotation) {
      setSelectedQuotation(quotation);
    } else {
      setSelectedQuotation(null);
    }
  };

  const fetchQuotationsByFilter = useCallback(async () => {
    setIsLoading(true);

    if (filterValue.trim().length > 0) {
      const res = await getMyQuotationsController({
        option: 'finished',
        filter: filterType,
        filter_value: filterValue,
      });

      if (res && res.length > 0) {
        setSearchedQuotations(res);
      } else {
        setSearchedQuotations([]);
      }
    } else {
      setSearchedQuotations([]);
    }
    setIsLoading(false);
  }, [filterType, filterValue]);

  const handleDateSearch = (period: string[]) => {
    // clear search state for invalid period
    if (period.length !== 2) {
      setFilterValue('');
    } else {
      const [initialDate, finalDate] = period;
      if (initialDate.trim().length > 0 && finalDate.trim().length > 0) {
        setFilterValue(`["${initialDate}", "${finalDate}"]`);
      }
    }
  };
  const handleSearch = (query: string) => {
    setFilterValue(query);
  };

  useEffect(() => {
    if (filterValue.trim().length > 0) {
      fetchQuotationsByFilter();
    }
  }, [fetchQuotationsByFilter, filterValue]);

  const fetchAnswerQuotationById = useCallback(async (id: string) => {
    setIsLoading(true);
    const res = await getAnswerQuotationByIdController(id);
    if (res) {
      setSearchedQuotationAnswer(res);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (selectedQuotation !== null && selectedQuotation.answers_quotation) {
      fetchAnswerQuotationById(
        selectedQuotation.answers_quotation[0].id_answer_quotation,
      );
    }
  }, [fetchAnswerQuotationById, selectedQuotation]);
  return {
    page,
    finishedQuotations,
    isLoading,
    noMoreData,
    loadMoreData,
    selectedQuotation,
    setSelectedQuotation,
    handleRowClick,
    isViewQuotationModalOpen,
    setIsViewQuotationModalOpen,
    filterType,
    filterValue,
    handleSearch,
    handleDateSearch,
    searchedQuotations,
    setFilterType,
    quotationReplies,
    searchedQuotationAnswer,
  };
};
