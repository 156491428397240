import {
  CarSimple,
  UserList,
  ListNumbers,
  Envelope,
  EnvelopeOpen,
} from '@phosphor-icons/react';
import { getUserData } from '../../../utils/workshopData';

interface Props {
  newQuotations: number;
  newQuotationAnswers: number;
}

export const useHomeCards = ({ newQuotationAnswers, newQuotations }: Props) => {
  const userData = getUserData();
  const permissions = (userData?.permissions ?? []).map(
    permission => permission.code,
  );
  const permissionType =
    permissions.includes('CREATE_QUOT') && permissions.includes('ANSWER_QUOT')
      ? 'quotation'
      : 'default';

  const quotationAccessContent = [
    {
      title: 'Orçamentos',
      description: 'Crie um novo orçamento',
      uri: '/budget',
      notifications: 0,
      icone: (
        <ListNumbers
          size={window.innerWidth === 480 ? 38 : 48}
          weight="bold"
          color="#007A37"
        />
      ),
    },
    {
      title: 'Cotações',
      description: 'Crie e gerencie suas cotações',
      uri: '/new-quotation',
      notifications: newQuotations,
      icone: (
        <Envelope
          size={window.innerWidth === 480 ? 38 : 48}
          weight="bold"
          color="#007A37"
        />
      ),
    },
    {
      title: 'Responder Cotações',
      description: 'Responda suas cotações recebidas',
      uri: '/answer-quotation',
      notifications: newQuotationAnswers,
      icone: (
        <EnvelopeOpen
          size={window.innerWidth === 480 ? 38 : 48}
          weight="bold"
          color="#007A37"
        />
      ),
    },

    {
      title: 'Consulta de veículos',
      description: 'Consulte dados de um veículo',
      uri: '/search-vehicle',
      notifications: 0,
      icone: (
        <CarSimple
          size={window.innerWidth === 480 ? 38 : 48}
          weight="bold"
          color="#007A37"
        />
      ),
    },

    {
      title: 'Meu Perfil',
      description: 'Acesse seus dados de cadastro',
      uri: '/profile',
      notifications: 0,
      icone: (
        <UserList
          size={window.innerWidth === 480 ? 38 : 48}
          weight="bold"
          color="#007A37"
        />
      ),
    },
  ];
  const defaultContent = [
    {
      title: 'Meu Perfil',
      description: 'Acesse seus dados de cadastro',
      uri: '/profile',
      notifications: 0,
      icone: (
        <UserList
          size={window.innerWidth === 480 ? 38 : 48}
          weight="bold"
          color="#007A37"
        />
      ),
    },
  ];
  const gasStationAgentAccessContent = [
    {
      title: 'Sistema de Pontos',
      description: 'Gerencie seus pontos',
      uri: '/points',
      icone: (
        <Envelope
          size={window.innerWidth === 480 ? 38 : 48}
          weight="bold"
          color="#007A37"
        />
      ),
    },
    {
      title: 'Meu Perfil',
      description: 'Acesse seus dados de cadastro',
      uri: '/profile',
      notifications: 0,
      icone: (
        <UserList
          size={window.innerWidth === 480 ? 38 : 48}
          weight="bold"
          color="#007A37"
        />
      ),
    },
  ];

  return {
    content:
      permissionType === 'quotation' ? quotationAccessContent : defaultContent,
  };
};
