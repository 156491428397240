import { ProductTable } from '../../../../../../../../../shared/ProductTable';
import { useResponsive } from '../../../../../../../../../hooks/useResponsive';
import styles from './quote-table-header.module.css';

export const QuoteTableHeader = () => {
  const { sizeMobile } = useResponsive();
  return (
    <div className={styles.container}>
      <ProductTable.Header
        style={{
          gridTemplateColumns: !sizeMobile ? 'repeat(4, 1fr)' : '.5fr 2fr 1fr',
          padding: !sizeMobile ? '0.25rem 2rem' : '1rem 1rem 1rem 2.5rem',
          maxHeight: !sizeMobile ? '2.5rem' : '4rem',
          height: !sizeMobile ? '2.5rem' : '4rem',
        }}
      >
        <span>Item</span>
        <span>Descrição</span>
        {!sizeMobile && <span>ref/marca</span>}
        {!sizeMobile ? <span>Qtd. Solicitada </span> : <span />}
      </ProductTable.Header>
    </div>
  );
};
