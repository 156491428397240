import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChecklistInput } from '../../@types/interface';
import { Repair } from '../../@types/Repair';

interface orderServiceState {
  orderServices: Repair[];
  orderService: Repair;
  checklistInput: ChecklistInput[];
}

export const initialOrderServiceState = {
  checklist_id: '',
  name: '',
  id_repair: '',
  OS: 0,
  date: '',
  time: '',
  finish_date: '',
  finish_time: '',
  message: '',
  comment: '',
  total: 0,
  discount: 0,
  km: 0,
  warranty: null,
  type: '',
  finished: false,
  defect: '',
  vehicle_id: '',
  vehicle: {
    brand: '',
    id_vehicle: '',
    transferred: false,
    name: '',
    model: '',
    year: '',
    yearModel: '',
    license_plate: '',
    color: '',
    active: false,
    img_url: '',
    user_id: '',
    locator_id: null,
    chassi: null,
    displacement: '',
    fuel: '',
    engine: '',
    county: '',
    uf: '',
    created_at: '',
    updated_at: '',
  },
  description: '',
  user_workshop_id: '',
  // user_workshop: {
  //   id_users_Workshop: '',
  //   favorite: false,
  //   workshop_id: '',
  //   user_id: '',
  //   user: {
  //     id_user: '',
  //     name: '',
  //     surname: '',
  //     email: '',
  //     phone: '',
  //     document: '',
  //     avatar: '',
  //     role_name: '',
  //     address_id: '',
  //     address: {
  //       city: '',
  //       created_at: '',
  //       district: '',
  //       id_address: '',
  //       lat: null,
  //       long: null,
  //       number: '',
  //       street: '',
  //       uf: '',
  //       updated_at: '',
  //       zip: '',
  //     },
  //     created_at: '',
  //     updated_at: '',
  //   },
  //   created_at: '',
  //   updated_at: '',
  // },
  child_services: [],
  status_id: null,
  status: null,
  created_at: '',
  updated_at: '',
  data_start: '',
  checklist: null,
  address: {
    id_address: '',
    lat: '',
    long: '',
    zip: '',
    street: '',
    uf: '',
    city: '',
    district: '',
    number: '',
  },
  email: '',
};

const initialState: orderServiceState = {
  orderServices: [],
  orderService: initialOrderServiceState,
  checklistInput: [],
};

export const orderServiceSlice = createSlice({
  name: 'orderService',
  initialState,
  reducers: {
    updateOrderServices: (state, action: PayloadAction<Repair[]>) => {
      state.orderServices = action.payload;
    },
    removeOrderService: (state, action: PayloadAction<string>) => {
      const filteredOrderServices = state.orderServices.filter(
        order => order.id_repair !== action.payload,
      );
      state.orderServices = filteredOrderServices;
    },
    addOrderServiceReducer: (state, action: PayloadAction<Repair[]>) => {
      const updatedOrderService = [...state.orderServices];
      const newServices = action.payload.filter(service => {
        return !updatedOrderService.some(
          existingService => existingService.id_repair === service.id_repair,
        );
      });

      updatedOrderService.push(...newServices);

      updatedOrderService.sort((a, b) => {
        const dateA = a.finished ? new Date(a.finish_date) : new Date(a.date);
        const dateB = b.finished ? new Date(b.finish_date) : new Date(b.date);

        const dateComparison = dateB.getTime() - dateA.getTime();

        if (!a.finished && b.finished) {
          return -1;
        }
        if (a.finished && !b.finished) {
          return 1;
        }

        return dateComparison;
      });

      state.orderServices = updatedOrderService;
    },
    updateOrderServiceReducer: (state, action: PayloadAction<Repair>) => {
      const updatedOrderService = state.orderServices.map(orderService => {
        if (orderService.OS === action.payload.OS) {
          return action.payload;
        }
        return orderService;
      });
      updatedOrderService.sort((a, b) => {
        const dateA = a.finished ? new Date(a.finish_date) : new Date(a.date);
        const dateB = b.finished ? new Date(b.finish_date) : new Date(b.date);

        const dateComparison = dateB.getTime() - dateA.getTime();

        if (!a.finished && b.finished) {
          return -1;
        }
        if (a.finished && !b.finished) {
          return 1;
        }

        return dateComparison;
      });
      state.orderServices = updatedOrderService;
    },
    resetOS: state => {
      state.orderService = initialOrderServiceState;
    },
    setOrderService: (state, action: PayloadAction<Repair>) => {
      state.orderService = { ...state.orderService, ...action.payload };
    },
    setChecklist: (state, action: PayloadAction<ChecklistInput[]>) => {
      state.checklistInput = action.payload;
    },
    resetChecklist: state => {
      state.checklistInput = [];
    },
  },
});

export const {
  updateOrderServices,
  addOrderServiceReducer,
  resetOS,
  resetChecklist,
  removeOrderService,
  setOrderService,
  updateOrderServiceReducer,
  setChecklist,
} = orderServiceSlice.actions;
export const orderServicesReducer = orderServiceSlice.reducer;
