import { useEffect } from 'react';
import styles from './not-answered-quotations.module.css';
import { ListTable } from '../../../../../../shared/ListTable';
import { NotAnsweredQuotationRow } from '../../components/NotAnsweredQuotationRow';
import { AnswerQuotation } from '../../components/modals/AnswerQuotation';
import answeredQuotationsIcon from '../../../../../../assets/icons/answered-quotations.svg';
import { useNotAnsweredQuotations } from './useNotAnsweredQuotations';
import { BlockedQuotationAlert } from '../../components/modals/BlockedQuotationAlert';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import { ViewNotAnsweredQuotationModal } from '../../components/modals/ViewNotAnsweredQuotationModal';
import { FilterComponent } from '../../../../../../shared/FilterComponent';
import { useFilteredAnswers } from '../../hooks/useFilteredAnswers';
import { GetMyAnswerFilters } from '../../interface';
import {
  getObjectFromLocal,
  removeObjectFromLocal,
} from '../../../../../../utils/localStorage';

export const NotAnsweredQuotations = () => {
  const {
    isLoading,
    loadMoreData,
    notAnsweredQuotations,
    page,
    selectedAnswerToView,
    setSelectedAnswerToView,
    isAnswerQuotationModalOpen,
    setIsAnswerQuotationModalOpen,
    handleRowClick,
    handleShowBlockedQuotationMessage,
    showBlockedQuotationMessage,
    isViewQuotationModalOpen,
    setIsViewQuotationModalOpen,
    // handleWatchState,
  } = useNotAnsweredQuotations();
  const { sizeMobile } = useResponsive();

  const {
    filterType,
    filterValue,
    handleDateSearch,
    handleSearch,
    searchedAnswers,
    setFilterType,
  } = useFilteredAnswers({
    // option: 'received',
    option: 'recieved',
  });

  const localAnswerId = getObjectFromLocal('answerId');

  useEffect(() => {
    if (localAnswerId && localAnswerId.length > 0) {
      removeObjectFromLocal('answerId');
      // handleWatchState({
      //   id: localAnswerId ?? '',
      //   state: 'on',
      // });
      setIsAnswerQuotationModalOpen(true);
    }
  }, [localAnswerId, setIsAnswerQuotationModalOpen]);

  return (
    <>
      <BlockedQuotationAlert
        isOpen={showBlockedQuotationMessage}
        onClose={() => handleShowBlockedQuotationMessage('close')}
      />
      <ViewNotAnsweredQuotationModal
        isOpen={isViewQuotationModalOpen}
        onClose={() => {
          setSelectedAnswerToView(null);
          setIsViewQuotationModalOpen(false);
        }}
        quotationAnswer={selectedAnswerToView}
      />

      <div className={styles.heading}>
        <div className={styles.describe}>
          <img src={answeredQuotationsIcon} alt="responder cotações" />
          <p>Cotações recebidas e propostas aguardando aprovação</p>
        </div>
        <div className={styles.actions}>
          <FilterComponent
            handleDateSearch={handleDateSearch}
            handleSearch={handleSearch}
            handleFilterChange={(filter: string) =>
              setFilterType(filter as GetMyAnswerFilters)
            }
            defaultValue="code"
            variant={filterType === 'date_recieved' ? 'date' : 'searchbar'}
            onlyNumber={filterType === 'code'}
            options={[
              {
                label: 'Número',
                value: 'code',
              },
              {
                label: 'Data',
                value: 'date_recieved',
              },
              {
                label: 'Placa',
                value: 'plate',
              },
              {
                label: 'Origem',
                value: 'origin',
              },
            ]}
          />
        </div>
      </div>
      <ListTable.Root
        isLoading={isLoading}
        isEmpty={!isLoading && notAnsweredQuotations.length === 0}
        notFound={filterValue.trim().length > 0 && searchedAnswers.length === 0}
        message="Nenhuma cotação encontrada"
      >
        <AnswerQuotation
          localAnswerId={localAnswerId || ''}
          isOpen={isAnswerQuotationModalOpen}
          onClose={() => {
            // await handleWatchState({
            //   id: selectedAnswerToView?.id_answer_quotation ?? '',
            //   state: 'off',
            // });
            setSelectedAnswerToView(null);
            setIsAnswerQuotationModalOpen(false);
          }}
          answer={selectedAnswerToView}
        />
        <div className={styles.container}>
          <ListTable.Head
            style={{
              gridTemplateColumns: sizeMobile
                ? '1.5fr 2.5fr 1.5fr 2fr'
                : '1fr repeat(3,1fr)',
            }}
          >
            <ListTable.HeadItem>
              {sizeMobile ? 'N° de cotação' : 'Número de cotação'}
            </ListTable.HeadItem>
            {/* {!sizeMobile && <ListTable.HeadItem>Expiração</ListTable.HeadItem>} */}
            <ListTable.HeadItem>Data de Criação</ListTable.HeadItem>
            <ListTable.HeadItem>Placa</ListTable.HeadItem>
            <ListTable.HeadItem>Origem</ListTable.HeadItem>
          </ListTable.Head>
          <ListTable.Body onScroll={() => loadMoreData(page)}>
            {/* if user has searched something it shows the response */}
            {(filterValue.trim().length > 0
              ? searchedAnswers
              : notAnsweredQuotations
            ).map(answer => (
              <NotAnsweredQuotationRow
                key={answer.id_answer_quotation}
                handleAnswer={async () => {
                  if (answer.quotation && answer.quotation.blocked) {
                    handleShowBlockedQuotationMessage('open');
                  } else {
                    // await handleWatchState({
                    //   id: selectedAnswerToView?.id_answer_quotation ?? '',
                    //   state: 'on',
                    // });
                    setIsAnswerQuotationModalOpen(true);
                  }
                }}
                onRowClick={() => handleRowClick(answer)}
                handleView={() => setIsViewQuotationModalOpen(true)}
                isSelected={
                  !!selectedAnswerToView &&
                  selectedAnswerToView.quotation_id === answer.quotation_id
                }
                answer={answer}
              />
            ))}
          </ListTable.Body>
        </div>
      </ListTable.Root>
    </>
  );
};
