import styles from './missed-quotations.module.css';
import { ListTable } from '../../../../../../shared/ListTable';
import answeredQuotationsIcon from '../../../../../../assets/icons/answered-quotations.svg';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import { ViewNotAnsweredQuotationModal } from '../../components/modals/ViewNotAnsweredQuotationModal';
import { useMissedQuotations } from './useMissedQuotations';
import { MissedQuotationRow } from '../../components/MissedQuotationRow';
import { useFilteredAnswers } from '../../hooks/useFilteredAnswers';
import { FilterComponent } from '../../../../../../shared/FilterComponent';
import { GetMyAnswerFilters } from '../../interface';

export const MissedQuotations = () => {
  const {
    isLoading,
    loadMoreData,
    page,
    selectedAnswerToView,
    setSelectedAnswerToView,
    handleRowClick,
    isViewQuotationModalOpen,
    setIsViewQuotationModalOpen,
    missedQuotations,
    handleWhatsappMessage,
  } = useMissedQuotations();
  const { sizeMobile } = useResponsive();

  const {
    filterType,
    filterValue,
    handleDateSearch,
    handleSearch,
    searchedAnswers,
    setFilterType,
  } = useFilteredAnswers({
    option: 'rejected',
  });

  return (
    <>
      <ViewNotAnsweredQuotationModal
        isOpen={isViewQuotationModalOpen}
        onClose={() => {
          setSelectedAnswerToView(null);
          setIsViewQuotationModalOpen(false);
        }}
        quotationAnswer={selectedAnswerToView}
      />

      <div className={styles.heading}>
        <div className={styles.describe}>
          <img src={answeredQuotationsIcon} alt="responder cotações" />
          <p>Cotações que foram perdidas</p>
        </div>
        <div className={styles.actions}>
          <FilterComponent
            handleDateSearch={handleDateSearch}
            handleSearch={handleSearch}
            handleFilterChange={(filter: string) =>
              setFilterType(filter as GetMyAnswerFilters)
            }
            defaultValue="code"
            variant={filterType === 'date_recieved' ? 'date' : 'searchbar'}
            onlyNumber={filterType === 'code'}
            options={[
              {
                label: 'Número',
                value: 'code',
              },
              {
                label: 'Data',
                value: 'date_recieved',
              },
              {
                label: 'Placa',
                value: 'plate',
              },
              {
                label: 'Origem',
                value: 'origin',
              },
            ]}
          />
        </div>
      </div>
      <ListTable.Root
        isLoading={isLoading}
        isEmpty={!isLoading && missedQuotations.length === 0}
        notFound={filterValue.trim().length > 0 && searchedAnswers.length === 0}
        message="Nenhuma cotação encontrada"
      >
        <div className={styles.container}>
          <ListTable.Head
            style={{
              gridTemplateColumns: sizeMobile
                ? '1.5fr 2.5fr 1.5fr 2fr'
                : '1fr 2.5fr 1fr 1fr',
            }}
          >
            <ListTable.HeadItem>
              {sizeMobile ? 'N° de cotação' : 'Número de cotação'}
            </ListTable.HeadItem>
            <ListTable.HeadItem>Data de Criação</ListTable.HeadItem>
            <ListTable.HeadItem>Placa</ListTable.HeadItem>
            <ListTable.HeadItem>Origem</ListTable.HeadItem>
          </ListTable.Head>
          <ListTable.Body onScroll={() => loadMoreData(page)}>
            {/* if user has searched something it shows the response */}
            {(filterValue.trim().length > 0
              ? searchedAnswers
              : missedQuotations
            ).map(answer => (
              <MissedQuotationRow
                key={answer.id_answer_quotation}
                onRowClick={() => handleRowClick(answer)}
                handleView={() => setIsViewQuotationModalOpen(true)}
                handleWhatsappMessage={() => handleWhatsappMessage(answer)}
                isSelected={
                  !!selectedAnswerToView &&
                  selectedAnswerToView.quotation_id === answer.quotation_id
                }
                answer={answer}
              />
            ))}
          </ListTable.Body>
        </div>
      </ListTable.Root>
    </>
  );
};
