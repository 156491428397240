import { AlertMessage } from '../../../../../../../shared/AlertMessage';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const BlockedQuotationAlert = ({ isOpen, onClose }: Props) => {
  return (
    <AlertMessage.Root
      isOpen={isOpen}
      onClose={onClose}
      disableScroll
      title="Cotação Finalizada"
    >
      <AlertMessage.Body>
        Essa cotação não aceita mais respostas, já atingiu o máximo de respostas
        ou foi finalizada
      </AlertMessage.Body>
    </AlertMessage.Root>
  );
};
