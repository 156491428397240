import { ListTable } from '../../../../../../shared/ListTable';
import { Quotation } from '../../../../../../@types/interface';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import { useQuotation } from '../../hooks/useQuotation';

interface Props {
  quotation: Quotation;
  handleViewReplies: () => void;
  isSelected: boolean;
  onRowClick: () => void;
}

export const CompletedQuotesRow = ({
  quotation,
  handleViewReplies,
  isSelected,
  onRowClick,
}: Props) => {
  const { sizeMobile } = useResponsive();
  const { handleShowDate } = useQuotation();
  return (
    <>
      <ListTable.Row
        style={{
          gridTemplateColumns: sizeMobile
            ? 'repeat(2, .25fr) repeat(3, 1fr)'
            : '1.5rem repeat(4, 1fr)',
        }}
        selected={isSelected}
        variant="caret"
        handleClick={onRowClick}
      >
        <ListTable.RowItem
          style={{
            display: 'flex',
            paddingLeft: '2rem',
          }}
        >
          {quotation?.QUOTATION ?? '----'}
        </ListTable.RowItem>
        <ListTable.RowItem>
          {(quotation?.answers_quotation &&
            quotation?.answers_quotation[0]?.workshop?.fantasy_name) ??
            '----'}
        </ListTable.RowItem>
        <ListTable.RowItem>
          {handleShowDate(quotation?.created_at)}
        </ListTable.RowItem>
        <ListTable.RowItem>
          {handleShowDate(quotation.updated_at)}
        </ListTable.RowItem>
      </ListTable.Row>
      {isSelected && (
        <ListTable.SubRow>
          <ListTable.ViewButton onClick={handleViewReplies} />
        </ListTable.SubRow>
      )}
    </>
  );
};
