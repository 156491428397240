import { X } from '@phosphor-icons/react';
import { Button } from '../../../Button';
import { COLORS } from '../../../../styles/colors';

interface Props {
  onClick: () => void;
  text?: string;
}

export const DeleteButton = ({ onClick, text = 'Excluir' }: Props) => {
  return (
    <Button handleClick={onClick} theme="delete">
      <X size={20} color={COLORS.WHITE} weight="bold" />
      {text}
    </Button>
  );
};
