import { useDispatch } from 'react-redux';
import {
  CheckCircle,
  WhatsappLogo,
  ChatText,
  Check,
  X,
  Hourglass,
} from 'phosphor-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ProductTable } from '../../../../../../../../../shared/ProductTable';
import { Textarea } from '../../../../../../../../../shared/Textarea';
import styles from './littleAnswer.module.css';
import {
  AnswerQuotation,
  ItemQuotation,
} from '../../../../../../../../../@types/interface';
import { editAnswerRebuttal } from '../../../../../../../../../features/quotation/quotation-reply-slice';
import { useAppSelector } from '../../../../../../../../../hooks/useAppSelector';
import { LittleQuoteItem } from '../LittleQuoteItem';
import { Button } from '../../../../../../../../../shared/Button';
import { ConfirmRejectAnswerModal } from '../../../../../../AnswerQuotation/components/modals/ConfirmRejectAnswerModal';
import { ApproveAnswerModal } from '../../../ApproveAnswerModal';

interface Props {
  quotationItems: ItemQuotation[];
  answer: AnswerQuotation;
  setInvalid: (invalid: boolean) => void;
  isTrembleWarning: boolean;
  handleWhatsappMessage: (phone: string) => void;
  isAnswered: boolean;
  handleApproveAnswer: (id: string) => void;
  handleRejectAnswer: (id: string) => void;
}
export const LittleAnswer = ({
  answer,
  quotationItems,
  setInvalid,
  isTrembleWarning,
  handleWhatsappMessage,
  isAnswered,
  handleApproveAnswer,
  handleRejectAnswer,
}: Props) => {
  const dispatch = useDispatch();
  const { approvedQuotation } = useAppSelector(state => state.quotationReducer);

  const approvedItems = approvedQuotation?.list_answers_approved.find(
    approvedItem =>
      approvedItem.answer_quotation_id === answer.id_answer_quotation,
  );

  const [subtotal, setSubtotal] = useState(0);
  const [isConfirmRejectionModalOpen, setIsConfirmRejectionModalOpen] =
    useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);

  const calculateNotApprovedSubtotal = useCallback(() => {
    const newSubTotal = quotationItems.reduce(
      (previousValue, quotationItem) => {
        const currentAnswer = answer.items_answer_quotation.find(
          answerItem =>
            answerItem.item_quotation_id === quotationItem.id_item_quotation,
        );

        if (currentAnswer) {
          const quantityAsked = parseFloat(`${quotationItem.quantity}`);
          const quantity = Math.min(
            quantityAsked,
            currentAnswer.quantity_available,
          );
          return previousValue + currentAnswer.total * quantity;
        }
        return previousValue;
      },
      0,
    );
    setSubtotal(newSubTotal);
  }, [answer?.items_answer_quotation, quotationItems]);

  const calculateApprovedSubtotal = useCallback(() => {
    const newSubtotal =
      (approvedItems &&
        approvedItems.items.reduce(
          (previousValue, currentValue) =>
            previousValue + currentValue.quantity_asked * currentValue.value,
          0,
        )) ||
      0;
    setSubtotal(newSubtotal);
  }, [approvedItems]);

  const calculateAnsweredSubtotal = useCallback(() => {
    const newSubtotal = answer.items_answer_quotation
      .filter(item => item.quantity_asked)
      .reduce(
        (previousValue, currentValue) =>
          previousValue +
          (currentValue?.quantity_asked ?? 0) * currentValue.total,
        0,
      );
    setSubtotal(newSubtotal);
  }, [answer.items_answer_quotation]);

  useEffect(() => {
    if (isAnswered) {
      calculateAnsweredSubtotal();
    } else if (approvedItems?.items.length === 0) {
      // if nothing is approved
      calculateNotApprovedSubtotal();
    } else {
      // if approve any item
      calculateApprovedSubtotal();
    }
  }, [
    approvedItems?.items.length,
    calculateAnsweredSubtotal,
    calculateApprovedSubtotal,
    calculateNotApprovedSubtotal,
    isAnswered,
  ]);

  const containerRef = useRef<HTMLDivElement>(null);
  const handleAnswerStatus = () => {
    if (answer.rejected)
      return (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '.25rem',
          }}
        >
          <X size={24} weight="duotone" color="var(--error)" />
          Rejeitado
        </span>
      );
    if (answer.finished)
      return (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '.25rem',
          }}
        >
          <CheckCircle size={24} weight="duotone" color="var(--primary)" />
          Envio Confirmado
        </span>
      );
    if (answer.confirmed)
      return (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '.25rem',
          }}
        >
          <Hourglass size={24} weight="duotone" color="var(--primary)" />
          Preparando Pedido
        </span>
      );
    return '';
  };

  return (
    <>
      <ConfirmRejectAnswerModal
        isOpen={isConfirmRejectionModalOpen}
        onClose={() => setIsConfirmRejectionModalOpen(false)}
        onConfirm={() => {
          handleRejectAnswer(answer.id_answer_quotation);
          setIsConfirmRejectionModalOpen(false);
        }}
        onCancel={() => setIsConfirmRejectionModalOpen(false)}
        container={containerRef.current || document.body}
      />
      <ApproveAnswerModal
        isOpen={isApproveModalOpen}
        onClose={() => setIsApproveModalOpen(false)}
        onApprove={() => {
          handleApproveAnswer(answer.id_answer_quotation);
          setIsApproveModalOpen(false);
        }}
        answer={answer}
        hasNoApprovedItems={approvedItems?.items.length === 0}
        container={containerRef.current || document.body}
      />
      <div className={styles.container} ref={containerRef}>
        <ProductTable.Root
          heading={
            <div className={styles.headingWrapper}>
              <h3>{answer?.workshop?.fantasy_name ?? ''}</h3>
              <ul className={styles.info}>
                <li>
                  <Button
                    handleClick={() =>
                      handleWhatsappMessage(answer?.workshop?.whatsapp)
                    }
                    style={{
                      height: '1.5rem',
                      backgroundColor: '#007a37',
                    }}
                  >
                    <WhatsappLogo size={24} weight="fill" />
                  </Button>
                </li>
                {handleAnswerStatus()}
              </ul>
            </div>
          }
          style={{
            gap: '0',
          }}
        >
          <ProductTable.Header
            style={{
              gridTemplateColumns: '1fr 2fr',
              justifyItems: 'center',
              alignContent: 'center',
              alignItems: 'center',
              padding: '0.5rem 1rem',
              backgroundColor: '#81c784',
            }}
          >
            <span
              style={{
                justifySelf: 'stretch',
                fontSize: '1rem',
              }}
            >
              Item
            </span>
            <span
              style={{
                fontSize: '1rem',
              }}
            >
              Descrição
            </span>
            <span />
          </ProductTable.Header>
          <ProductTable.Body>
            <div>
              {quotationItems.map((quotationItem, index) => {
                const answerItems = answer.items_answer_quotation.filter(
                  item =>
                    item.item_quotation_id === quotationItem.id_item_quotation,
                );

                return (
                  <>
                    <div className={styles['item-description']}>
                      <span>{index + 1}</span>
                      <span>{quotationItem?.description ?? ''}</span>
                    </div>
                    <ul className={styles['items-list']}>
                      <div className={styles['list-header']}>
                        <span>confirmação</span>
                        <span>valor</span>
                      </div>

                      {answerItems.map((answerSubItem, subIndex) => (
                        <LittleQuoteItem
                          quotationItem={quotationItem}
                          answer={answerSubItem}
                          key={quotationItem.id_item_quotation}
                          isTrembleWarning={isTrembleWarning}
                          isAnswered={isAnswered}
                        />
                      ))}
                    </ul>
                  </>
                );
              })}
            </div>

            <div className={styles.subtotal}>
              {isAnswered ||
              (approvedItems &&
                approvedItems.items &&
                approvedItems.items.length > 0) ? (
                <b>Total</b>
              ) : (
                <div className={styles['subtotal-info']}>
                  <b>Sub-Total</b>
                  <span>(Cálculo com os menores preços)</span>
                </div>
              )}
              <span>
                {subtotal.toLocaleString('pt-br', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })}
              </span>
            </div>
          </ProductTable.Body>

          {(answer?.comment ?? '').trim().length > 0 && (
            <div className={styles.observation}>
              <b>
                <ChatText size={24} color="var(--primary)" /> Comentário do
                fornecedor:
              </b>
              <p>{answer.comment}</p>
            </div>
          )}

          <div className={styles.observation}>
            <h1>
              {!isAnswered ? (
                <>
                  Adicionar Observação <span>(Opcional)</span>
                </>
              ) : (
                `${(answer?.rebuttal ?? '').length > 0 ? 'Observação' : ''}`
              )}
            </h1>

            {!isAnswered ? (
              <Textarea
                handleChange={e => {
                  dispatch(
                    editAnswerRebuttal({
                      answer_quotation_id: answer.id_answer_quotation,
                      updatedValue: e.target.value,
                    }),
                  );
                }}
                name="Observação"
                disabled={isAnswered}
                style={{
                  height: '5rem',
                  fontWeight: '500',
                  color: '#000000',
                }}
              />
            ) : (
              <p>{answer?.rebuttal ?? ''}</p>
            )}
          </div>
          {!isAnswered && (
            <div className={styles.actions}>
              <Button
                handleClick={() => setIsConfirmRejectionModalOpen(true)}
                style={{
                  borderRadius: '4px',
                  background: '#E53835',
                  width: '120px',
                  padding: '.5rem',
                  textAlign: 'center',
                }}
              >
                <X weight="fill" color="var(--white)" size={18} /> Rejeitar
                cotação
              </Button>

              <Button
                handleClick={() => setIsApproveModalOpen(true)}
                style={{
                  borderRadius: '4px',
                  background: 'var(--shade_1)',
                  width: '120px',
                  padding: '.5rem',
                  textAlign: 'center',
                }}
              >
                <Check weight="fill" color="var(--white)" size={18} /> Aprovar
                selecionados
              </Button>
            </div>
          )}
        </ProductTable.Root>
      </div>
    </>
  );
};
