import { ListTable } from '../../../../../../shared/ListTable';
import { AnswerQuotation } from '../../../../../../@types/interface';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import { Circle } from '../../../../../../shared/Circle';
import { useQuotationAnswer } from '../../hooks/useQuotationAnswer';

interface Props {
  answer: AnswerQuotation;
  handleView: () => void;
  onRowClick: () => void;
  handleWhatsapp: () => void;
  isSelected: boolean;
}

export const ApprovedQuotationRow = ({
  answer,
  handleView,
  onRowClick,
  isSelected,
  handleWhatsapp,
}: Props) => {
  const { sizeMobile } = useResponsive();
  const { handleShowDate } = useQuotationAnswer();

  const handleQuotationStatus = () => {
    if (answer?.quotation?.blocked) return 'disabled';
    if (!answer.visualized) return 'notification';
    return 'default';
  };

  return (
    <>
      <ListTable.Row
        style={{
          gridTemplateColumns: !sizeMobile
            ? '2.5rem .7fr repeat(2, 1fr) 2fr'
            : '0.4fr 0.5fr 1fr 1fr 2fr',
          fontSize: !sizeMobile ? '' : '13px',
          gap: sizeMobile ? '0.5rem' : '0',
        }}
        selected={isSelected}
        variant="caret"
        handleClick={onRowClick}
      >
        <ListTable.RowItem
          style={{
            display: 'flex',
            paddingLeft: !sizeMobile ? '2rem' : '0.5rem',
          }}
        >
          <Circle
            style={{ marginRight: '.5rem' }}
            variant={handleQuotationStatus()}
          />
          {answer?.quotation?.QUOTATION ?? '----'}
        </ListTable.RowItem>
        <ListTable.RowItem>
          {handleShowDate(answer?.quotation?.created_at ?? '---')}
        </ListTable.RowItem>
        <ListTable.RowItem>
          {handleShowDate(answer?.quotation?.updated_at ?? '---')}
        </ListTable.RowItem>
        <ListTable.RowItem>
          {answer?.quotation?.workshop?.fantasy_name ?? '----'}
        </ListTable.RowItem>
      </ListTable.Row>
      {isSelected && (
        <ListTable.SubRow>
          <ListTable.ViewButton onClick={handleView} />
          <ListTable.WhatsappButton onClick={handleWhatsapp} />
        </ListTable.SubRow>
      )}
    </>
  );
};
