import { Flag, PaperPlaneRight } from '@phosphor-icons/react';
import { ListTable } from '../../../../../../shared/ListTable';
import { AnswerQuotation } from '../../../../../../@types/interface';
import { Button } from '../../../../../../shared/Button';
import { Circle } from '../../../../../../shared/Circle';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import { useQuotationAnswer } from '../../hooks/useQuotationAnswer';
import { CountDownTimer } from '../../../../../../shared/CountDownTimer';

interface Props {
  handleAnswer: () => void;
  onRowClick: () => void;
  handleView: () => void;
  isSelected: boolean;
  answer: AnswerQuotation;
}

export const NotAnsweredQuotationRow = ({
  handleAnswer,
  onRowClick,
  isSelected,
  handleView,
  answer,
}: Props) => {
  const { sizeMobile } = useResponsive();
  const { handleShowDate } = useQuotationAnswer();
  const { quotation } = answer;
  const isAnswered = answer.answered;

  const handleQuotationStatus = () => {
    if (!answer.answered) return 'notification';
    return 'default';
  };

  return (
    <>
      <ListTable.Row
        style={{
          gridTemplateColumns: sizeMobile
            ? '0.5rem .5fr 2.5fr 1fr 2fr'
            : '1.5rem repeat(4, 1fr)',
          color: quotation?.blocked ? 'var(--grey)' : '',
        }}
        selected={isSelected}
        variant="caret"
        handleClick={onRowClick}
      >
        <ListTable.RowItem
          style={{
            display: 'flex',
            paddingLeft: sizeMobile ? '.8rem' : '2rem',
            alignItems: 'center',
          }}
        >
          <div
            style={{ position: 'relative', left: sizeMobile ? '0' : '-10px' }}
          >
            <Circle
              style={{ marginRight: '.5rem' }}
              variant={handleQuotationStatus()}
            />
          </div>

          {quotation?.QUOTATION}
        </ListTable.RowItem>
        {/* {!sizeMobile && (
          <ListTable.RowItem>
            <CountDownTimer
              expirationHours={quotation?.hours ?? 0}
              initialDate={
                quotation?.created_at
                  ? new Date(quotation?.created_at)
                  : new Date()
              }
            />
          </ListTable.RowItem>
        )} */}
        <ListTable.RowItem>
          {handleShowDate(quotation?.created_at ?? '---')}
        </ListTable.RowItem>
        <ListTable.RowItem>
          {quotation?.vehicle?.license_plate ?? (
            <div style={{ width: '65px', margin: '0 auto' }}>----</div>
          )}
        </ListTable.RowItem>
        <ListTable.RowItem>
          {quotation?.workshop?.fantasy_name ?? '----'}
        </ListTable.RowItem>
      </ListTable.Row>
      {isSelected && (
        <ListTable.SubRow>
          {/* {sizeMobile && (
            <CountDownTimer
              expirationHours={quotation?.hours ?? 0}
              initialDate={quotation?.created_at ?? new Date()}
            />
          )} */}
          {quotation?.blocked && (
            <Button
              handleClick={handleAnswer}
              style={{
                background: 'var(--grey)',
              }}
            >
              <Flag size={20} weight="fill" />
              Respostas Encerradas
            </Button>
          )}
          {!quotation?.blocked && !isAnswered && (
            <Button handleClick={handleAnswer}>
              <PaperPlaneRight size={20} weight="fill" />
              Responder
            </Button>
          )}
          {isAnswered && <ListTable.ViewButton onClick={handleView} />}
        </ListTable.SubRow>
      )}
    </>
  );
};
