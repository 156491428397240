import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AnswerQuotation } from '../../../../../../../@types/interface';
import {
  confirmQuotationController,
  getAnswerQuotationByIdController,
} from '../../../controller';
import {
  removeNotConfirmedQuotation,
  updateNotApprovedQuotation,
} from '../../../../../../../features/answerQuotation/answer-quotation';

interface Quote {
  description: string;
  reference: string;
  quantity: string;
  value: string;
  availableQuantity: string;
}

export interface FormValues {
  plate?: string;
  vehicle?: string;
  chassi?: string;
  year?: string;
  model?: string;
  quotes: Quote[];
  observation?: string;
  paymentMethod: string;
}

interface Props {
  quotationAnswer: AnswerQuotation | null;
  onClose: () => void;
  isOpen: boolean;
}

export const useApproveQuotation = ({
  onClose,
  quotationAnswer,
  isOpen,
}: Props) => {
  const { control, reset } = useForm<FormValues>({
    defaultValues: {
      chassi: '',
      model: '',
      plate: '',
      vehicle: '',
      year: '',
      quotes: [
        {
          description: '',
          quantity: '',
          reference: '',
          availableQuantity: '',
          value: '',
        },
      ],
      observation: '',
      paymentMethod: '',
    },
  });
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isConfirmingQuotation, setIsConfirmingQuotation] = useState(false);

  const dispatch = useDispatch();

  // set answer as visualized
  const fetchQuotationAnswer = useCallback(
    async (answerId: string) => {
      const res = await getAnswerQuotationByIdController(answerId);
      if (res) dispatch(updateNotApprovedQuotation(res));
    },
    [dispatch],
  );

  useEffect(() => {
    if (quotationAnswer && isOpen)
      fetchQuotationAnswer(quotationAnswer.id_answer_quotation);
  }, [fetchQuotationAnswer, isOpen, quotationAnswer]);

  useEffect(() => {
    if (quotationAnswer) {
      reset({
        chassi: quotationAnswer.quotation?.vehicle?.chassi ?? '',
        model: quotationAnswer.quotation?.vehicle?.model ?? '',
        plate: quotationAnswer.quotation?.vehicle?.license_plate ?? '',
        vehicle: quotationAnswer.quotation?.vehicle?.name ?? '',
        year: quotationAnswer.quotation?.vehicle?.year ?? '',
        quotes:
          quotationAnswer?.quotation?.items_quotation?.map(item => ({
            description: item?.description ?? '',
            quantity: item?.quantity ?? '',
            reference: item?.reference ?? '',
            availableQuantity: '',
            value: '',
          })) ?? [],
        observation: quotationAnswer?.quotation?.comment ?? '',
        paymentMethod:
          quotationAnswer?.quotation?.type_payment ?? 'Não informado',
      });
    }
  }, [quotationAnswer, reset]);

  const handleConfirmQuotation = async () => {
    setIsConfirmModalOpen(false);
    setIsConfirmingQuotation(true);

    const res = await confirmQuotationController(
      quotationAnswer?.id_answer_quotation ?? '',
    );

    if (res) {
      dispatch(removeNotConfirmedQuotation(res.id_answer_quotation));
    }
    setIsConfirmingQuotation(false);
    handleConfirmModal('close');
    onClose();
  };
  const handleConfirmModal = (action: 'open' | 'close') => {
    if (action === 'close') setIsConfirmModalOpen(false);
    else setIsConfirmModalOpen(true);
  };

  return {
    control,
    isConfirmModalOpen,
    setIsConfirmModalOpen,
    handleConfirmQuotation,
    handleConfirmModal,
    isConfirmingQuotation,
  };
};
