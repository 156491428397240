import { CopySimple, ArchiveBox, CheckSquare } from '@phosphor-icons/react';
import React, { useState } from 'react';
import { LockSimple } from 'phosphor-react';
import { Circle } from '../../../../../../shared/Circle';
import { PageLayout } from '../../../../../../shared/PageLayout';
import { AnswerQuotation } from '../../../../../../@types/interface';
import styles from './little-header-quotation.module.css';

type Props = {
  answeredQuotationList: AnswerQuotation[];
  setSelectedPage: (
    screen: 'notAnswered' | 'approved' | 'finished' | 'missed',
  ) => void;
  selectedPage: 'notAnswered' | 'approved' | 'finished' | 'missed';
};

export const LittleHeaderAnswerQuotation = ({
  answeredQuotationList,
  setSelectedPage,
  selectedPage,
}: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(true);
  const handleNameButton = () => {
    if (selectedPage === 'notAnswered') {
      return ['Recebidas', <ArchiveBox size={32} weight="fill" />];
    }
    if (selectedPage === 'missed') {
      return ['Perdidas', <LockSimple size={32} weight="fill" />];
    }
    if (selectedPage === 'approved') {
      return ['Aprovadas', <CheckSquare size={32} weight="fill" />];
    }
    return ['Finalizadas', <CopySimple size={32} weight="fill" />];
  };

  const handleHideButton = () => {
    if (selectedPage === 'notAnswered') {
      return 'notAnswered';
    }
    if (selectedPage === 'approved') {
      return 'approved';
    }
    if (selectedPage === 'missed') {
      return 'missed';
    }
    return 'finished';
  };

  // useEffect(() => {
  //   setDropdownOpen(false);
  // }, [selectedPage]);

  return (
    <div className={styles.contentButton}>
      <PageLayout.TopMenuButton
        onClick={() => console.log('setDropdownOpen(prev => !prev)')}
        isSelected
      >
        {handleNameButton()[1]}
        {handleNameButton()[0]}
        {selectedPage === 'notAnswered' &&
          answeredQuotationList.length > 0 &&
          ` (${answeredQuotationList.length})`}{' '}
        {selectedPage === 'notAnswered' && answeredQuotationList.length > 0 && (
          <Circle animation="pulse" variant="notification" />
        )}
        {/* <CaretRight
          size={32}
          weight="fill"
          color="#0b5226"
          className={`${dropdownOpen ? styles.rotation : ''}`}
        /> */}
      </PageLayout.TopMenuButton>

      {dropdownOpen && (
        <div
          className={`${styles.containerDropDownOpen} ${
            styles[handleHideButton()]
          }`}
        >
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'notAnswered'}
            onClick={() => setSelectedPage('notAnswered')}
          >
            <ArchiveBox size={32} weight="fill" />
            Recebidas
            {answeredQuotationList.length > 0 &&
              ` (${answeredQuotationList.length})`}{' '}
            {answeredQuotationList.length > 0 && (
              <Circle animation="pulse" variant="notification" />
            )}
          </PageLayout.TopMenuButton>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'approved'}
            onClick={() => {
              setSelectedPage('approved');
            }}
          >
            <CheckSquare size={32} weight="fill" />
            Aprovadas
          </PageLayout.TopMenuButton>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'missed'}
            onClick={() => {
              setSelectedPage('missed');
            }}
          >
            <LockSimple size={32} weight="fill" />
            Perdidas
          </PageLayout.TopMenuButton>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'finished'}
            onClick={() => setSelectedPage('finished')}
          >
            <CopySimple size={32} weight="fill" />
            Finalizadas
          </PageLayout.TopMenuButton>
        </div>
      )}
    </div>
  );
};
