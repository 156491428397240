/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import { Eye, EyeSlash } from 'phosphor-react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../shared/Button';
import styles from './register.module.css';
import { maskPhone } from '../../../../services/helpers/mask';
import { ModalSuccess } from '../../../../shared/ModalSuccess/ModalSuccess';
import { getUserData } from '../../../../utils/workshopData';
import { COLORS } from '../../../../styles/colors';
import { updateUserPasswordController } from './controller';
import { AgentViewModal } from '../../components/AgentViewModal';

export interface Address {
  id_address: string;
  zip: string;
  street: string;
  uf: string;
  city: string;
  district: string;
  number: string;
}

export interface User {
  email: string;
}

const ChangePassword = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState(getUserData().phone);
  const [isAgentViewModalOpen, setIsAgentViewModalOpen] = useState(false);

  const [modalSuccess, setModalSuccess] = useState(false);

  const userData = getUserData();
  const permissions = (userData?.permissions ?? []).map(
    permission => permission.code,
  );
  const permissionType =
    permissions.includes('CREATE_QUOT') && permissions.includes('ANSWER_QUOT')
      ? 'quotation'
      : 'default';

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalSuccess]);

  const onSubmit = async () => {
    if (newPassword.length > 0 || confirmPassword.length > 0) {
      const res = await updateUserPasswordController({
        newPassword: newPassword.trim(),
        confirmPassword: confirmPassword.trim(),
        phone: phone.trim(),
      });

      if (res) {
        userData.agents[0].is_first_access = false;
        localStorage.setItem('@AutoCenter: user', JSON.stringify(userData));
        if (localStorage.getItem('hasToChangePassword')) {
          localStorage.removeItem('hasToChangePassword');
          navigate('/home');
        }
      }
    }
  };

  return (
    <div className={styles.container}>
      <AgentViewModal
        isOpen={isAgentViewModalOpen}
        onClose={() => setIsAgentViewModalOpen(false)}
        data={getUserData()}
      />
      <h1>Editar Dados</h1>

      <form>
        <section className={styles.section}>
          {permissionType.trim().toLowerCase() !== 'quotation' && (
            <div className={styles['form-row']}>
              <div
                className={styles['input-control']}
                style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
              >
                <label>Código de vendedor</label>
                <Button
                  variant="icon"
                  handleClick={() => setIsAgentViewModalOpen(true)}
                  style={{
                    background: 'var(--secondary)',
                    borderRadius: '4px',
                    height: '0.8rem',
                    width: '0.8rem',
                    padding: '0.4rem 0.6rem',
                  }}
                  icon={<Eye size={18} weight="fill" color="var(--white)" />}
                />
              </div>
            </div>
          )}
        </section>
        <section className={styles.section}>
          <div className={styles['form-row']}>
            <div className={styles['input-control']}>
              <label htmlFor="phone">Trocar seu telefone (opcional)</label>
              <input
                placeholder="Telefone"
                name="Phone"
                type="text"
                value={maskPhone(phone)}
                onChange={e => {
                  setPhone(e.target.value);
                }}
              />
            </div>
          </div>
        </section>
        <section className={styles.section}>
          <div className={styles['form-row']}>
            <div className={styles['input-control']}>
              <label htmlFor="newPassword">Trocar sua senha</label>
              <input
                placeholder="Nova Senha"
                name="newPassword"
                type={passwordVisible ? 'text' : 'password'}
                value={newPassword}
                onChange={e => {
                  setNewPassword(e.target.value);
                }}
              />
              <button
                type="button"
                className={styles.icon}
                onClick={() =>
                  setPasswordVisible(currentValue => !currentValue)
                }
              >
                {passwordVisible ? (
                  <Eye size="2rem" color={COLORS.CHARCOAL} />
                ) : (
                  <EyeSlash size="2rem" color={COLORS.CHARCOAL} />
                )}
              </button>
            </div>
            <div className={styles['input-control']}>
              <input
                placeholder="Confirme sua senha"
                type="password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
        </section>
      </form>

      <button
        type="button"
        className={styles['save-button']}
        onClick={onSubmit}
      >
        Salvar
      </button>

      <ModalSuccess setShowModal={setModalSuccess} showModal={modalSuccess}>
        Alterado com sucesso!
      </ModalSuccess>
    </div>
  );
};

export default ChangePassword;
