import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  setAnsweredQuotations,
  setFinishedQuotations,
  setNotAnsweredQuotations,
} from '../../../../../features/quotation/quotation-reply-slice';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { getAllMyQuotationsController } from '../controller';
import {
  formatDate,
  dateOffset,
  formatDateToMobile,
} from '../../../../../utils/dateFunctions';
import { useResponsive } from '../../../../../hooks/useResponsive';
import { QUOTATION } from '../../../../../constants';

let isQuotationIntervalCreated = false;

export const useQuotation = () => {
  const dispatch = useDispatch();
  const { sizeMobile } = useResponsive();
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const { answeredQuotationList } = useAppSelector(
    state => state.quotationReducer,
  );

  const fetchQuotations = useCallback(async () => {
    const res = await getAllMyQuotationsController({
      limit: QUOTATION.REQUEST_LIMIT,
      page: 1,
    });

    if (res) {
      const { answered, finished, not_answered } = res;
      dispatch(setAnsweredQuotations(answered));
      dispatch(setNotAnsweredQuotations(not_answered));
      dispatch(setFinishedQuotations(finished));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isQuotationIntervalCreated) {
      // first request
      fetchQuotations();

      // define request interval
      isQuotationIntervalCreated = true;
      intervalRef.current = setInterval(fetchQuotations, QUOTATION.TIME_UPDATE);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        isQuotationIntervalCreated = false;
      }
    };
  }, [fetchQuotations]);

  const handleShowDate = (date: Date | string) => {
    if (date && !sizeMobile) {
      return formatDate(dateOffset(new Date(date)));
    }
    if (date && sizeMobile) {
      return formatDateToMobile(dateOffset(new Date(date)));
    }
    return '---';
  };

  return {
    answeredQuotationList,
    fetchQuotations,
    handleShowDate,
  };
};
