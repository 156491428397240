import { useCallback, useEffect, useState } from 'react';
import { getMyAnswersController } from '../../controller';
import { AnswerQuotation } from '../../../../../../@types/interface';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import { sendWhatsappMessage } from '../../../../../../utils/sendWhatsappMessage';

const LIMIT = 80;

export const useMissedQuotations = () => {
  const { rejectedQuotationList } = useAppSelector(
    state => state.answerQuotationReducer,
  );

  const [page, setPage] = useState(1);
  const [missedQuotations, setMissedQuotations] = useState<AnswerQuotation[]>(
    rejectedQuotationList,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);
  const [selectedAnswerToView, setSelectedAnswerToView] =
    useState<AnswerQuotation | null>(null);
  const [isAnswerQuotationModalOpen, setIsAnswerQuotationModalOpen] =
    useState(false);

  const [isViewQuotationModalOpen, setIsViewQuotationModalOpen] =
    useState(false);

  const fetchNotAnsweredQuotations = useCallback(
    async (pageRequest: number) => {
      setIsLoading(true);
      if (!noMoreData) {
        const res = await getMyAnswersController({
          limit: LIMIT,
          page: pageRequest,
          option: 'rejected',
        });

        if (res) {
          setMissedQuotations(previous => {
            const newAnswers = res.filter(
              answer =>
                !previous.some(
                  existingQuotation =>
                    answer.id_answer_quotation ===
                    existingQuotation.id_answer_quotation,
                ),
            );
            return [...previous, ...newAnswers];
          });
        } else {
          setNoMoreData(true);
        }
      }
      setIsLoading(false);
    },
    [noMoreData],
  );

  const loadMoreData = useCallback(
    (previousPage = 1) => {
      if (!noMoreData) {
        fetchNotAnsweredQuotations(previousPage);
        setPage(previousPage + 1);
      }
    },
    [fetchNotAnsweredQuotations, noMoreData],
  );

  useEffect(() => {
    loadMoreData();
  }, [loadMoreData]);
  useEffect(() => {
    if (rejectedQuotationList) {
      setMissedQuotations(previous => {
        const newAnswers = rejectedQuotationList.filter(
          answer =>
            !previous.some(
              existingQuotation =>
                answer.id_answer_quotation ===
                existingQuotation.id_answer_quotation,
            ),
        );
        return [...previous, ...newAnswers];
      });
    }
  }, [rejectedQuotationList]);

  const handleRowClick = (answer: AnswerQuotation) => {
    if (
      !selectedAnswerToView ||
      selectedAnswerToView.quotation_id !== answer.quotation_id
    ) {
      setSelectedAnswerToView(answer);
    } else {
      setSelectedAnswerToView(null);
    }
  };

  const handleWhatsappMessage = (answer: AnswerQuotation) => {
    sendWhatsappMessage(
      `Olá *${
        answer?.quotation?.workshop?.fantasy_name ?? ''
      }*, obtivemos uma resposta recusada para a cotação *${
        answer?.quotation?.QUOTATION ?? ''
      }*, poderiamos conversar sobre a oferta ?`,
      answer.quotation?.workshop?.whatsapp ?? '',
    );
  };

  return {
    page,
    loadMoreData,
    fetchNotAnsweredQuotations,
    selectedAnswerToView,
    setSelectedAnswerToView,
    isLoading,
    isAnswerQuotationModalOpen,
    setIsAnswerQuotationModalOpen,
    handleRowClick,
    isViewQuotationModalOpen,
    setIsViewQuotationModalOpen,
    missedQuotations,
    handleWhatsappMessage,
  };
};
