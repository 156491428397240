import { ListTable } from '../../../../../../shared/ListTable';
import { AnswerQuotation } from '../../../../../../@types/interface';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import { useQuotationAnswer } from '../../hooks/useQuotationAnswer';

interface Props {
  onRowClick: () => void;
  handleView: () => void;
  isSelected: boolean;
  handleWhatsappMessage: () => void;
  answer: AnswerQuotation;
}

export const MissedQuotationRow = ({
  onRowClick,
  isSelected,
  handleView,
  handleWhatsappMessage,
  answer,
}: Props) => {
  const { sizeMobile } = useResponsive();
  const { handleShowDate } = useQuotationAnswer();

  const { quotation } = answer;

  return (
    <>
      <ListTable.Row
        style={{
          gridTemplateColumns: sizeMobile
            ? '0.5rem .5fr 2.5fr 1fr 2fr'
            : '1.5rem 1fr 2.5fr 1fr 1fr',
          color: quotation?.blocked ? 'var(--grey)' : '',
        }}
        selected={isSelected}
        variant="caret"
        handleClick={onRowClick}
      >
        <ListTable.RowItem
          style={{
            display: 'flex',
            paddingLeft: sizeMobile ? '1.5rem' : '2rem',
          }}
        >
          {quotation?.QUOTATION}
        </ListTable.RowItem>
        <ListTable.RowItem>
          {handleShowDate(quotation?.created_at ?? '---')}
        </ListTable.RowItem>
        <ListTable.RowItem>
          {quotation?.vehicle?.license_plate ?? '----'}
        </ListTable.RowItem>
        <ListTable.RowItem>
          {quotation?.workshop?.fantasy_name ?? '----'}
        </ListTable.RowItem>
      </ListTable.Row>
      {isSelected && (
        <ListTable.SubRow>
          <ListTable.ViewButton onClick={handleView} />
          <ListTable.WhatsappButton onClick={handleWhatsappMessage} />
        </ListTable.SubRow>
      )}
    </>
  );
};
