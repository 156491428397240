import { AlertMessage } from '../../../../../../../shared/AlertMessage';
import { Quotation } from '../../../../../../../@types/interface';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  onBlock: () => void;
  quotation?: Quotation | null;
}

export const BlockModal = ({
  isOpen,
  onClose,
  isLoading = false,
  onBlock,
  quotation = null,
}: Props) => {
  return (
    <AlertMessage.Root
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      title="Finalizar Respostas da Cotação"
      footer={
        <AlertMessage.Footer>
          {!isLoading && (
            <>
              <AlertMessage.CancelButton onClick={onBlock}>
                Concluir e finalizar respostas
              </AlertMessage.CancelButton>
              <AlertMessage.ConfirmButton onClick={onClose}>
                Concluir e aguardar respostas
              </AlertMessage.ConfirmButton>
            </>
          )}
        </AlertMessage.Footer>
      }
    >
      <AlertMessage.Body>
        <p>
          Deseja Bloquear respostas da cotação{' '}
          <b>{quotation && `N° ${quotation?.QUOTATION ?? ''}`}</b>? Ela não
          poderá mais receber nenhuma resposta!
        </p>
      </AlertMessage.Body>
    </AlertMessage.Root>
  );
};
