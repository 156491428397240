/* eslint-disable jsx-a11y/label-has-associated-control */
import { CirclesThreePlus } from '@phosphor-icons/react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './quantity-field.module.css';
import { Checkbox } from '../../../../../../shared/Checkbox';
import { Input } from '../../../../../../shared/Input';
import {
  addAnswerItem,
  editAnswerItem,
  removeAnswerItem,
} from '../../../../../../features/quotation/quotation-reply-slice';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import { ApprovedItems } from '../../../../../../features/quotation/interfaces';

interface Props {
  disabled?: boolean;
  maxValue: number;
  name: string;
  item_answer_quotation_id: string;
  answer_quotation_id: string;
  unitValue: number;
  trembleWarning: boolean;
  setInvalid: (invalid: boolean) => void;
  defaultValue?: string;
}

export const QuantityField = ({
  disabled = false,
  maxValue,
  name,
  item_answer_quotation_id,
  answer_quotation_id,
  unitValue,
  trembleWarning = false,
  setInvalid,
  defaultValue = '',
}: Props) => {
  const dispatch = useDispatch();
  const { approvedQuotation } = useAppSelector(state => state.quotationReducer);

  const [isSelected, setIsSelected] = useState(false);
  const [quantity, setQuantity] = useState(defaultValue);
  const [isInvalid, setIsInvalid] = useState(false);

  const handleToggleSelected = () => {
    if (!disabled)
      setIsSelected(previous => {
        const newValue = !previous;
        handleCheckboxChange(newValue);
        return newValue;
      });
  };

  const handleQuantityChange = (value: string) => {
    const parsedValue = parseFloat(value.replace('.', ',')) || 0;
    if (parsedValue > maxValue || parsedValue < 0) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
      handleInputChange(value);
    }
  };

  const handleCheckboxChange = useCallback(
    (isChecked: boolean) => {
      if (approvedQuotation) {
        if (isChecked) {
          const parsedValue = parseFloat(quantity);
          const newItem: ApprovedItems = {
            quantity_asked: !isNaN(parsedValue) ? parsedValue : 0,
            item_answer_quotation_id,
            value: unitValue,
          };

          dispatch(
            addAnswerItem({
              answer_quotation_id,
              newItem,
            }),
          );
        } else {
          dispatch(
            removeAnswerItem({
              answer_quotation_id,
              itemId: item_answer_quotation_id,
            }),
          );
        }
      }
    },
    [
      answer_quotation_id,
      approvedQuotation,
      dispatch,
      item_answer_quotation_id,
      quantity,
    ],
  );

  const handleInputChange = (value: string) => {
    const parsedValue = parseFloat(value);
    dispatch(
      editAnswerItem({
        answer_quotation_id,
        itemId: item_answer_quotation_id,
        newQuantityAsked: !isNaN(parsedValue) ? parsedValue : 0,
      }),
    );
  };
  useEffect(() => {
    setInvalid(isInvalid);
  }, [isInvalid]);

  return (
    <div
      className={`${styles['quantity-field']} ${
        !disabled && isSelected ? styles.selected : ''
      }`}
    >
      <div
        className={`${styles['label-select']} ${
          isInvalid ? styles.invalid : ''
        }`}
      >
        <Checkbox
          name={name}
          style={{
            marginBottom: '.25rem',
          }}
          theme="lightgreen"
          isChecked={!disabled && isSelected}
          disabled={disabled}
          handleChange={handleToggleSelected}
        />
        <Input
          name="quantity"
          value={quantity}
          handleChange={e => {
            const maskedValue = e.target.value
              .replace(/[^\d.,]/g, '')
              .replace('.', ',');
            setQuantity(maskedValue);
            handleQuantityChange(maskedValue);
          }}
          pattern="([0-9]+)"
          disabled={!disabled && !isSelected}
          style={{
            margin: 0,
          }}
        />
      </div>
      <span className={styles['quantity-description']}>
        Qtd. disponível: <b>{maxValue}</b>
      </span>
      {isInvalid && (
        <span
          className={`${styles.warning} ${
            trembleWarning ? styles.warningAnimation : ''
          }`}
        >
          informe uma quantidade válida
        </span>
      )}
    </div>
  );
};
