import styles from './finished-quotations.module.css';
import { ListTable } from '../../../../../../shared/ListTable';
import { CompletedQuotesRow } from '../../components/CompletedQuotesRow';
import createdQuotationsIcon from '../../../../../../assets/icons/created-quotations.svg';
import { useFinishedQuotation } from './useFinishedQuotations';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import { FilterComponent } from '../../../../../../shared/FilterComponent';
import { ViewFinishedQuotationModal } from '../../components/modals/ViewFinishedQuotationModal';

export const FinishedQuotations = () => {
  const {
    page,
    finishedQuotations,
    isLoading,
    loadMoreData,
    selectedQuotation,
    handleRowClick,
    setIsViewQuotationModalOpen,
    isViewQuotationModalOpen,
    setSelectedQuotation,
    filterType,
    filterValue,
    handleSearch,
    handleDateSearch,
    searchedQuotations,
    setFilterType,
    searchedQuotationAnswer,
  } = useFinishedQuotation();
  const { sizeMobile } = useResponsive();

  return (
    <>
      <ViewFinishedQuotationModal
        isOpen={isViewQuotationModalOpen}
        onClose={() => {
          setIsViewQuotationModalOpen(false);
          setSelectedQuotation(null);
        }}
        answer={searchedQuotationAnswer}
      />

      <div className={styles.heading}>
        <div className={styles.describe}>
          <img src={createdQuotationsIcon} alt="cotações criadas" />
          <p>Cotações Concluídas</p>
        </div>
        <div className={styles.actions}>
          <FilterComponent
            handleDateSearch={handleDateSearch}
            handleSearch={handleSearch}
            handleFilterChange={(filter: string) =>
              setFilterType(filter as 'code' | 'date')
            }
            defaultValue="code"
            variant={filterType === 'code' ? 'searchbar' : 'date'}
            onlyNumber={filterType === 'code'}
            options={[
              {
                label: 'Número',
                value: 'code',
              },
              {
                label: 'Data',
                value: 'date',
              },
            ]}
          />
        </div>
      </div>
      <ListTable.Root
        isLoading={isLoading}
        isEmpty={!isLoading && finishedQuotations.length === 0}
        notFound={
          filterValue.trim().length > 0 && searchedQuotations.length === 0
        }
        message="Nenhuma cotação encontrada"
      >
        <div className={styles.container}>
          <ListTable.Head
            style={{
              gridTemplateColumns: '1rem repeat(4, 1fr)',
            }}
          >
            <ListTable.HeadItem />

            <ListTable.HeadItem>
              {sizeMobile ? 'N° de cotação' : 'Número de cotação'}
            </ListTable.HeadItem>
            <ListTable.HeadItem>Fornecedor</ListTable.HeadItem>
            <ListTable.HeadItem>Data de Criação</ListTable.HeadItem>
            <ListTable.HeadItem>Data da Finalização</ListTable.HeadItem>
          </ListTable.Head>
          <ListTable.Body onScroll={() => loadMoreData(page)}>
            {/* if user has searched something it shows the response */}
            {(filterValue.trim().length > 0
              ? searchedQuotations
              : finishedQuotations
            ).map(quotation => (
              <CompletedQuotesRow
                key={quotation.id_quotation}
                quotation={quotation}
                handleViewReplies={() => setIsViewQuotationModalOpen(true)}
                isSelected={
                  !!selectedQuotation &&
                  !!selectedQuotation.answers_quotation &&
                  !!quotation.answers_quotation &&
                  selectedQuotation.answers_quotation[0].id_answer_quotation ===
                    quotation.answers_quotation[0].id_answer_quotation
                }
                onRowClick={() => handleRowClick(quotation)}
              />
            ))}
          </ListTable.Body>
        </div>
      </ListTable.Root>
    </>
  );
};
