import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFormTrigger,
} from 'react-hook-form';
import { PlusSquare } from '@phosphor-icons/react';
import { Input } from '../../../../../../../../../shared/Input';
import { ProductTable } from '../../../../../../../../../shared/ProductTable';
import { Textarea } from '../../../../../../../../../shared/Textarea';
import styles from './items.module.css';
import { FormValues } from '../../useQuotationForm';
import { Button } from '../../../../../../../../../shared/Button';
import { VehicleResponse } from '../../../../../../../../../services/dataFetchers/VehicleDataFetcher/model';
import { Separator } from '../../../../../../../../../shared/Separator';
import { VehicleInfo } from '../../components/VehicleInfo';
import { ProductTableHeader } from '../../components/ProductTableHeader';
import { ProductTableRow } from '../../components/ProductTableRow';

interface Props {
  control: Control<FormValues>;
  quotes: FieldArrayWithId<FormValues, 'quotes', 'id'>[];
  handleAddRow: () => void;
  handleImageChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleRemoveImage: (name: string) => void;
  imagesList: File[];
  handleVehicleFields: (vehicle: VehicleResponse) => void;
  remove: (index: number) => void;
  setIsEditable: (isEdit: boolean) => void;
  trigger: UseFormTrigger<FormValues>;
}

export const Items = ({
  control,
  quotes,
  handleAddRow,
  handleImageChange,
  handleRemoveImage,
  imagesList,
  handleVehicleFields,
  remove,
  setIsEditable,
  trigger,
}: Props) => {
  return (
    <>
      <VehicleInfo
        control={control}
        handleVehicleFields={handleVehicleFields}
      />
      <ProductTable.Root
        heading={<h1 className={styles.label}>Itens para cotação:</h1>}
      >
        <ProductTableHeader />
        <ProductTable.Body>
          {quotes.map((quote, index) => (
            <ProductTableRow
              key={quote.id}
              control={control}
              index={index}
              onRemove={() => {
                // must be have at least one row
                if (quotes.length === 1) {
                  remove(index);
                  handleAddRow();
                } else {
                  remove(index);
                }
              }}
            />
          ))}
        </ProductTable.Body>
        <Separator theme="square" />

        <Button
          handleClick={handleAddRow}
          variant="register"
          style={{
            fontFamily: 'Bebas Neue',
            fontSize: '1rem',
            borderRadius: '4px',
            background: 'var(--primary)',
            padding: '0.35rem 0.5rem',
          }}
        >
          {' '}
          <PlusSquare size={24} weight="fill" /> Adicionar Item
        </Button>
      </ProductTable.Root>

      <div className={styles.containerListImages}>
        <Input
          name="checklist"
          type="imagePicker"
          handleChange={e => {
            handleImageChange(e as ChangeEvent<HTMLInputElement>);
          }}
          img_urlArray={imagesList.map(image => ({
            url: URL.createObjectURL(image),
            name: image.name,
          }))}
          removeImage={handleRemoveImage}
        />
      </div>
      <div className={styles.observation}>
        <Controller
          name="comment"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Textarea
              name={name}
              handleChange={onChange}
              value={value}
              label="Observações:"
            />
          )}
        />
      </div>
    </>
  );
};
