import { AlertMessage } from '../../../../../../../shared/AlertMessage';
import { Quotation } from '../../../../../../../@types/interface';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  onFinish: () => void;
  quotation?: Quotation | null;
}

export const FinishModal = ({
  isOpen,
  onClose,
  isLoading = false,
  onFinish,
  quotation = null,
}: Props) => {
  return (
    <AlertMessage.Root
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      title={!isLoading ? 'Confirmar Finalização' : 'Finalizando cotação'}
      footer={
        <AlertMessage.Footer>
          {!isLoading && (
            <>
              <AlertMessage.CancelButton onClick={onFinish}>
                finalizar Cotação
              </AlertMessage.CancelButton>
              <AlertMessage.ConfirmButton onClick={onClose}>
                Voltar
              </AlertMessage.ConfirmButton>
            </>
          )}
        </AlertMessage.Footer>
      }
    >
      <AlertMessage.Body>
        <p>
          Deseja finalizar a cotação{' '}
          <b>{quotation && `N° ${quotation?.QUOTATION ?? ''}`}</b>? Ela não
          poderá mais receber nenhuma resposta!
        </p>
      </AlertMessage.Body>
    </AlertMessage.Root>
  );
};
