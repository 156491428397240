import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const GlobalRedirect = () => {
  const location = useLocation();
  // const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('@AutoCenter: accessToken');

    // Redirecionar para a página externa se o token existir
    if (token) {
      window.location.href = `https://oficinas.autocenterapp.com/page-login?token=${token}`;
    }
  }, [location]);

  return null;
};

export default GlobalRedirect;
